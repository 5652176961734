import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Cascader, Space, AutoComplete } from 'antd';
import {
  Wrapper,
  AttachmentUpload,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  Modal,
  Select,
  TreeSelect,
  Input,
  Form,
  FormTitle,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';

import {
  getOrganizationWithCodeWithCurrent,
  getPhysicalModelList,
  OrganizationResponse,
  PhysicalModelRes,
} from '../../../api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { useQuery } from '../../../utils/utils';
import { getDeviceTypeTree } from '../../../api/deviceType';
import {
  getBatchDeviceTemplateProperty,
  BatchDeviceTemplatePropertyResponse,
  createBatchDeviceTemplateProperty,
  updateBatchDeviceTemplateProperty,
  copyBatchDeviceTemplateProperty,
} from '../../../api/batch';

import { getModel, ModelResponse } from '../../../api/model';
import qs from 'qs';
import {
  convertDataToEntities,
  DataNode as CascadeDataNode,
  formatToDataNode,
  getDeviceType,
} from '@/shared/utils/cascade-util';
import MultiModalSelect from '@/shared/components/MultiModalSelect';
import { checkOuIsBound } from '../../../api/implement';

import { getDefaultKey } from '@/shared/api/fileCenter';
import DeviceTags, { deviceTagsValidator } from '@/shared/components/DeviceTags';
import { formatDeviceTypeToDataNode } from '@/shared/utils/utils';

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};

const routes = [{ name: '资产信息' }];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const CreateBatchDeviceTemplatePropertyInfo: FC<{ isEdit?: boolean; isNext?: boolean }> = ({
  isEdit = false,
  isNext = false,
}) => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const { copyId = '' }: { copyId?: string } = qs.parse(search, { ignoreQueryPrefix: true });

  const defaultImgKey = useAsync(getDefaultKey, {});

  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';

  const navigate = useNavigate();
  const [selectedMcid, setSelectedMcid] = useState<string>();

  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [form] = Form.useForm();

  const [batchDeviceTemplate, setBatchDeviceTemplateProperty] = useState<BatchDeviceTemplatePropertyResponse>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getBatchDeviceTemplateProperty(id).then(res => {
        setBatchDeviceTemplateProperty(res);
      });
    }
  }, [id]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);

  const { data: deviceTypes } = useQuery(useCallback(() => getDeviceTypeTree(), []));
  const [deviceType, setDeviceType] = useState<number>();

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表

  useEffect(() => {
    if (deviceType) {
      // 物模型型号
      getPhysicalModelList({ deviceTypeIds: [deviceType] }).then(res => setPhysicalModelList(res.list));
    }
  }, [deviceType]);

  const physicalModelIdOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
  }, [physicalModelList]);

  useEffect(() => {
    if (batchDeviceTemplate) {
      form.setFieldsValue({
        typeId: batchDeviceTemplate.typeName,
        modelName: batchDeviceTemplate.modelName,
        pic: batchDeviceTemplate.pic,
        customerMcid: batchDeviceTemplate.customerMcid,
        assetCode: batchDeviceTemplate.assetCode,
        ouIds: batchDeviceTemplate.ouIds,
        tags: batchDeviceTemplate.tags ? batchDeviceTemplate.tags.split(',') : [],
        physicalModelId: batchDeviceTemplate.modelNo,
      });
      setSelectedMcid(batchDeviceTemplate.customerMcid);
    }
  }, [batchDeviceTemplate, form]);

  useEffect(() => {
    if (copyId && deviceTypes) {
      getBatchDeviceTemplateProperty(copyId).then(res => {
        const tree: CascadeDataNode[] = formatToDataNode(deviceTypes.tree as any);
        const entities = convertDataToEntities(tree);
        const deviceType = getDeviceType(entities, res.typeId!);
        setDeviceType(res.typeId);
        form.setFieldsValue({
          typeId: deviceType,
          modelName: res.modelName,
          pic: res.pic,
          ouIds: res.ouIds,
          customerMcid: res.customerMcid,
          assetCode: res.assetCode,
          tags: res.tags ? res.tags.split(',') : [],
          physicalModelId: res.physicalModelId,
        });
        setSelectedMcid(res.customerMcid);
      });
    }
  }, [copyId, deviceTypes, form]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const onFinish = async (type: boolean) => {
    try {
      const v = await form.validateFields();
      let promise: () => Promise<BatchDeviceTemplatePropertyResponse>;
      if (isEdit) {
        // 编辑
        promise = () =>
          updateBatchDeviceTemplateProperty({
            id: Number(id),
            pic: v.pic ?? defaultImgKey['defaultDeviceKey'],
            customerMcid: v.customerMcid,
            assetCode: v.assetCode,
            ouIds: v.ouIds,
            tags: v.tags.join(','),
          });
      } else {
        if (copyId) {
          promise = () =>
            copyBatchDeviceTemplateProperty({
              copyId: Number(copyId),
              typeId: deviceType,
              modelName: v.modelName,
              pic: v.pic ?? defaultImgKey['defaultDeviceKey'],
              customerMcid: v.customerMcid,
              assetCode: v.assetCode,
              ouIds: v.ouIds,
              tags: v.tags.join(','),
              physicalModelId: v.physicalModelId,
            });
        } else {
          // 添加
          promise = () =>
            createBatchDeviceTemplateProperty({
              typeId: deviceType,
              modelName: v.modelName,
              pic: v.pic ?? defaultImgKey['defaultDeviceKey'],
              customerMcid: v.customerMcid,
              assetCode: v.assetCode,
              ouIds: v.ouIds,
              tags: v.tags.join(','),
              physicalModelId: v.physicalModelId,
            });
        }
      }
      const submit = () =>
        promise().then(res => {
          if (type) {
            navigate(`/device/manage/batch/${res.id}/attribute/create?tabs=${tabs}`);
          } else {
            navigate(`/device/manage/batch?tabs=${tabs}`);
          }
        });
      let needWarn = v.ouIds?.length ? await checkOuIsBound({ addOuIds: v.ouIds, oldOuIds: [] }) : false;
      if (needWarn) {
        Modal.confirm({
          title: '导入模板存在多个运营单元',
          content: (
            <>
              <span style={{ color: 'red' }}>设备导入后，存在多个运营单元中，可能会有数据重复</span>，你还要继续吗？
            </>
          ),
          okText: '继续',
          onOk: submit,
        });
      } else {
        await submit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const [model, setModel] = useState<ModelResponse[]>([]);

  useEffect(() => {
    if (deviceType !== undefined) {
      getModel(deviceType).then(setModel);
    }
  }, [deviceType]);

  const [searchValue, setSearchValue] = useState<string>();

  const options = useMemo(() => {
    return model.filter(i => i.name.includes(searchValue ?? '')).map(i => ({ label: i.name, value: i.name }));
  }, [model, searchValue]);

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const onBlur = () => {
    setSearchValue(undefined);
  };

  const onCancel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      okText: '确定',
      onOk: () => {
        navigate(`/device/manage/batch?tabs=${tabs}`);
      },
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="资产信息"></FormTitle>
      <SubContent>
        <Form form={form} layout="vertical" {...formLayout}>
          <Row>
            <Col span={8}>
              <Form.Item name="typeId" label="所属类目" rules={[{ required: true, message: '请选择所属类目' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <Cascader
                    allowClear={false}
                    style={{ width: '100%' }}
                    options={formatDeviceTypeToDataNode(deviceTypes?.tree ?? [])}
                    fieldNames={{ label: 'name', value: 'id' }}
                    onChange={(e: any) => {
                      if (e.length < 3) {
                        form.setFieldsValue({ typeId: undefined });
                      }
                      setDeviceType(e[2] as number | undefined);
                      form.setFieldsValue({
                        modelName: undefined,
                        physicalModelId: undefined,
                      });
                    }}
                    placeholder="请选择"
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="physicalModelId" label="物模型型号">
                <Select
                  allowClear
                  optionFilterProp="label"
                  showSearch
                  options={physicalModelIdOptions}
                  disabled={!form.getFieldValue('typeId')}
                  placeholder={form.getFieldValue('typeId') ? '请选择' : '请先选择所属类目'}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="modelName" label="所属型号" rules={[{ required: true, message: '请选择所属型号' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <AutoComplete
                    disabled={deviceType === undefined}
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="请输入"
                    onSearch={onSearch}
                    onBlur={onBlur}
                    optionFilterProp="label"
                    options={options}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="customerMcid" label="所属组织" rules={[{ required: true, message: '请选择所属组织' }]}>
                <TreeSelect
                  onChange={val => {
                    setSelectedMcid(val as string);
                    form.setFieldsValue({ ouIds: [] });
                  }}
                  treeData={treeData}
                  placeholder="请选择"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="assetCode" label="资产编码" rules={[{ max: 500, message: '最多输入500位' }]}>
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="pic" label="设备图片">
                <AttachmentUpload fileSize={5} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouIds" label="运营单元">
                <MultiModalSelect mcid={selectedMcid} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="tags" label="设备标签" initialValue={[]} rules={[{ validator: deviceTagsValidator }]}>
                <DeviceTags />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Space size={8} className="sticky-footer-left">
          {isNext && (
            <Button
              type="primary"
              onClick={() => {
                onFinish(true);
              }}
            >
              保存并添加固定属性模板
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              onFinish(false);
            }}
          >
            保存
          </Button>
          <Button className={styles.button} onClick={onCancel}>
            取消
          </Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default CreateBatchDeviceTemplatePropertyInfo;
