import { Key, fetch } from '@maxtropy/components';
import qs from 'qs';
import { PageResponse, PageRequest } from './page';

export interface StationTreeItem {
  workCenterId: Key;
  centerProcedureLinkId: Key; // 中心工序关联id
  stationId: Key;
  procedureStationLinkId: Key; // 工序工站关联id
  iconKey?: string;
  stationName: string; // 工站名称
}

export interface ProcedureTreeItem {
  workCenterId: Key;
  procedureId: Key;
  centerProcedureLinkId: Key; // 中心工序关联id
  procedureName: string; //工序名称
  iconKey?: string;
  stationTree: StationTreeItem[];
}

// 左侧树
export interface WorkCenterTreeItem {
  id: Key;
  name: string;
  iconKey?: string;
  procedureTree: ProcedureTreeItem[];
}

export const getWorkCenterTree = () => {
  return fetch<WorkCenterTreeItem[]>('/api/work-center/tree');
};

export interface WorkOrderRes {
  id: Key;
  code: string; // 编码
  name: string; // 名称
  state: number; // 状态 1:启用，2:禁用
  deleteFlag: number; // 逻辑删除 0:否，1：是
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  updateName: string; // 操作人
  isUse: boolean; // 是否被映射，映射后无法废弃和修改编码
  opUpdateTime: string; // 最后更新时间(对工作中心下的操作)
  opUpdateName: string; // 最后操作人(对工作中心下的操作)
  workCenterCode: string;
  workCenterName: string;
}

export const getAllWorkOrderList = () => {
  return fetch<{ list: WorkOrderRes[] }>(
    `/api/v2/work-procedure/list`,
    {
      method: 'POST',
    },
    true
  );
};

export const getBindWorkOrderListById = (id: Key) => {
  return fetch<{ list: WorkOrderRes[] }>(
    `/api/v2/work-procedure/boundList`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

// 根据关联id获取工序信息
export const getWorkOrderDetailByLinkId = (id: Key) => {
  return fetch<WorkOrderRes>(
    `/api/v2/work-procedure/linkInfo`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

export interface WorkCenterRes {
  id: Key;
  code: string; // 编码
  name: string; // 名称
  state: number; // 状态
  deleteFlag: number; // 逻辑删除 0:否，1：是
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  updateName: string; // 操作人
  customerName: string; //  组织id
  isUse: boolean; // 是否被映射，映射后无法废弃和修改编码
  opUpdateTime: string; // 最后更新时间(对工作中心下的操作)
  opUpdateName: string; // 最后操作人(对工作中心下的操作)
}

// 获取工作中心详情
export const getWorkCenterDetailById = (id: Key) => {
  return fetch<WorkCenterRes>(`/api/work-center/${id}`);
};

export interface WorkStationRes {
  id: Key;
  code: string; // 编码
  name: string; // 名称
  state: number; // 状态 1:启用，2:禁用
  workProcedureId: Key; // 工序id
  workProcedureName: string; // 工序名称
  workProcedureCode: string; // 工序编码
  updateTime: string; // 修改时间
  updateName: string; // 操作人
  isMapping: number; // 0->没有被映射 1->被映射
}

// 根据工序id 获取工站列表
export const getWorkStationListById = (id: Key) => {
  return fetch<{ list: WorkStationRes[] }>(
    `/api/v2/work-station/list`,
    {
      method: 'POST',
      body: JSON.stringify({
        workProcedureId: id,
      }),
    },
    true
  );
};

// 获取已绑定工站列表
export const getBindWorkStationList = (id: Key) => {
  return fetch<{ list: WorkStationRes[] }>(
    `/api/v2/work-station/bound-list`,
    {
      method: 'POST',
      body: JSON.stringify({
        centerProcedureLinkId: id,
      }),
    },
    true
  );
};

export interface BindOrderBody {
  id: Key;
  workProcedureIds: Key[];
}

// 绑定工序
export const bindWorkOrder = (body: BindOrderBody) => {
  return fetch('/api/work-center/bindProcedure', {
    method: 'POST',
    body: JSON.stringify(body),
  });
};
export interface BindStationBody {
  centerProcedureLinkId: Key;
  workStationIds: Key[];
}
// 绑定工站
export const bindWorkStation = (body: BindStationBody) => {
  return fetch(
    `/api/v2/work-procedure/bindStation`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export interface WorkStationLinkRes {
  id: Key;
  code: string; // 编码
  name: string; // 名称
  state: number; // 状态
  deleteFlag: number; // 逻辑删除 0:否，1：是
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  updateName: string; // 操作人
  isUse: boolean; // 是否被映射，映射后无法废弃和修改编码
  opUpdateTime: string; // 最后更新时间(对工作中心下的操作)
  opUpdateName: string; // 最后操作人(对工作中心下的操作)
}

// 根据关联id获取工站信息
export const getWorkStationDetailByLinkId = (id: Key) => {
  return fetch<WorkStationLinkRes>(
    `/api/v2/work-station/link-info`,
    {
      method: 'POST',
      body: JSON.stringify({ procedureStationLinkId: id }),
    },
    true
  );
};

export enum OrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface WorkOrderQuery extends PageRequest {
  workCenterId?: Key; // 工作中心id
  workProcedureCodeOrName?: string; // 工序名或者工序编号
  productionBaseId?: number;
  orderBy?: OrderEnum;
}

export interface WorkOrderPageRes {
  workCenterId?: Key;
  linkId: Key;
  workProcedureId: Key; // 工序id
  workProcedureName: string; // 工序名称
  workProcedureCode: string; // 工序编号
  opUpdateName: string; // 最后操作人
  opUpdateTime: string; // 最后操作时间
  workStationAmount: number; // 绑定工站数
  energyUnitAmount: number; // 绑定用能单元数
}

// 查询工序分页
export const getWorkOrderPageById = (query: WorkOrderQuery) => {
  return fetch<PageResponse<WorkOrderPageRes>>(
    `/api/work-center/workProcedure/page?${qs.stringify(query, { indices: false })}`
  );
};
export interface WorkCenterQuery extends PageRequest {
  workCenterCodeOrName?: string;
  productionBaseId?: Key;
  orderBy?: OrderEnum;
}

export interface WorkCenterPageRes {
  workCenterId: Key;
  linkId: Key;
  workProcedureId: Key; // 工序id
  workProcedureName: string; // 工序名称
  workProcedureCode: string; // 工序编号
  opUpdateName: string; // 最后操作人
  opUpdateTime: string; // 最后操作时间
  workStationAmount: number; // 绑定工站数
  energyUnitAmount: number; // 绑定用能单元数
}

// 查询工序分页
export const getWorkCenterPageById = (query: WorkCenterQuery) => {
  return fetch<PageResponse<WorkCenterPageRes>>(
    `/api/work-center/workCenter/page?${qs.stringify(query, { indices: false })}`
  );
};

export interface WorkStationQuery extends PageRequest {
  workCenterId?: Key; // 工作中心id
  workProcedureLinkId?: Key; // 工序linkId 查某个工序link下的工站时需要填入
  workStationCodeOrName?: string; // 工站名或者工站编号
  productionBaseId?: number;
  orderBy?: OrderEnum;
}

export interface WorkStationPageRes {
  workCenterId: Key;
  workProcedureLinkId: Key;
  linkId: Key;
  workProcedureId: Key;
  workProcedureName: string;
  workProcedureCode: string;
  opUpdateName: string;
  opUpdateTime: string;
  workStationId: Key;
  workStationName: string;
  workStationCode: string;
  energyUnitAmount: number;
}

// 工站分页
export const getWorkStationPageById = (query: WorkStationQuery) => {
  return fetch<PageResponse<WorkStationPageRes>>(
    `/api/work-center/workStation/page?${qs.stringify(query, { indices: false })}`
  );
};

// 用能单元分页查询
export interface UnitQuery extends PageRequest {
  workCenterId?: Key; // 工作中心id
  workProcedureLinkId?: Key; // 工序linkId (非必填，默认查整个工作中心，查某个工序link或者工站link下的用能单元时需要填入)
  workStationLinkId?: Key; // 工站linkId (非必填，默认查整个工作中心，查某个工站link下的用能单元时需要填入)
  energyUnitName?: string; // 工序名或者工序编号
  orderBy?: string;
  productionBaseId?: number;
}

export interface UnitPageRes {
  workCenterId: Key;
  workProcedureLinkId: Key;
  workStationLinkId: Key;
  workProcedureId: Key;
  workProcedureName: string;
  workProcedureCode: string;
  workStationId: Key;
  workStationName: string;
  workStationCode: string;
  linkId: Key;
  energyUnitId: Key;
  energyUnitName: string;
}

export const getUnitPageById = (query: UnitQuery) => {
  return fetch<PageResponse<UnitPageRes>>(
    `/api/work-center/energyUnit/page?${qs.stringify(query, { indices: false })}`
  );
};

export interface EnergyUnitTreeItem {
  energyUnitId: string; // 用能单元id
  name: string; // 用能单元名称
  energyUnitGroupId: string;
  parentId: string;
  disabled: boolean | undefined; // 是否可选（true:不可选）
  children: EnergyUnitTreeItem[];
}
export interface EnergyUnitTreeItemDataNode {
  title: string; // 用能单元id
  key: string; // 用能单元名称
  parentId?: string;
  disabled?: boolean | undefined; // 是否可选（true:不可选）
  children: EnergyUnitTreeItemDataNode[];
}

export const getEnergyUnitTree = (id: Key) => {
  return fetch<{ list: EnergyUnitTreeItem[] }>(
    `/api/v2/work-station/unit-tree`,
    {
      method: 'POST',
      body: JSON.stringify({
        energyGroupId: id,
      }),
    },
    true
  );
};

export interface BindUnitBody {
  procedureStationLinkId: Key; // 工序工站关联id
  addUnitIds: Key[]; // 新增用能单元id
  delUnitIds: Key[]; // 删除用能单元id
}

export const BindUnit = (body: BindUnitBody) => {
  return fetch(
    '/api/v2/work-station/bindUnit',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export interface HasBindUnitItem {
  energyUnitId: Key; // 用能单元id
  energyUnitName: string; // 用能单元名称
}

export const getHasBindUnitList = (query: { procedureStationLinkId: Key }) => {
  return fetch<{ list: HasBindUnitItem[] }>(
    `/api/v2/work-station/bound-unit-list`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
};
