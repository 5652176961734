import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Button } from '@maxtropy/components';
import { EnergyUnitMedium, getMediumById, MediumQuery } from '@/api/productionConsumptionBoard';
import { EnergyMediumIconDisplay, EnergyMediumSceneIds } from '@/pages/ProductionEnergyConsumptionBoard/chartConfigs';
import generalActive from '../../images/generalActive.png';
import generalPassive from '../../images/generalPassive.png';
import styles from './index.module.scss';

// 综合能耗 id 为 1, 类似比如工质, 电是 2001, 新水是 2003(虚拟)
const GENERAL_CONSUMPTION = 1;
// 综合能耗场景 id 为 1, 类似比如电是 0, 给排水用水是 500
export const GENERAL_CONSUMPTION_SCENE = 1;

const RadioConsumption = (props: any) => {
  const { selectedKeys, selectedBasePhysicalQuantityId, setSelectedBasePhysicalQuantityId, generalQuery } = props;

  // 工质列表, 根据左侧 id 获取  获取的每一次切换工作中心拿到的所有能源介质
  const [mediums, setMediums] = useState<EnergyUnitMedium[]>([]);
  // 顶部选择综合能耗还是能源介质, 综合能耗的 id 为 1, 其他能源介质为其 id
  const [selectedConsumption, setSelectedConsumption] = useState<number>(GENERAL_CONSUMPTION);

  // 根据选择的工作中心/工序/工站/用能单元 id 获取顶部工质
  useEffect(() => {
    if (selectedKeys.length) {
      const query: MediumQuery = {
        ...generalQuery,
      };

      getMediumById(query).then(res => {
        // 切换工作中心时，判断这个工作中心上有没有现在的能源介质，有保留，没有回到综合能耗
        const found = res.list?.some(item => item.id === selectedConsumption);

        if (found) {
          setSelectedConsumption(selectedConsumption);
        } else {
          setSelectedConsumption(GENERAL_CONSUMPTION);
        }

        setMediums(res.list || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalQuery, selectedKeys]);

  return (
    <Space size={8} wrap>
      <Button
        style={{ paddingLeft: 12, paddingRight: 16 }}
        onClick={() => {
          // 综合能耗 id 为 0
          setSelectedConsumption(GENERAL_CONSUMPTION);
          setSelectedBasePhysicalQuantityId(undefined);
        }}
        className={
          selectedConsumption === GENERAL_CONSUMPTION ? styles.titleButtonChecked : styles.titleButtonUnChecked
        }
      >
        <Space size="small" align="center">
          <img width={24} src={selectedConsumption === GENERAL_CONSUMPTION ? generalActive : generalPassive} alt="" />
          <span>综合能耗</span>
        </Space>
      </Button>

      {mediums.map(medium => (
        <Button
          style={{ paddingLeft: 12, paddingRight: 16 }}
          onClick={() => {
            setSelectedConsumption(medium.energyMediumId);
            setSelectedBasePhysicalQuantityId(medium.basePhysicalQuantityId);
          }}
          className={
            `${selectedBasePhysicalQuantityId}-${selectedConsumption}` ===
            `${medium.basePhysicalQuantityId}-${medium.energyMediumId}`
              ? styles.titleButtonChecked
              : styles.titleButtonUnChecked
          }
        >
          <Space size="small" align="center">
            <img
              width={24}
              src={
                selectedConsumption === medium.energyMediumId
                  ? EnergyMediumIconDisplay[medium.sceneId as EnergyMediumSceneIds].active
                  : EnergyMediumIconDisplay[medium.sceneId as EnergyMediumSceneIds].passive
              }
              alt=""
            />
            <span>
              {medium.energyMediumName}-{medium.generalName}
            </span>
          </Space>
        </Button>
      ))}
    </Space>
  );
};

export default RadioConsumption;
