import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { Layout } from 'antd';
import DragResize from '@/components/DragResize';
import styles from './index.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import StationTree from './components/StationTree';
import RadioConsumption from './components/RadioConsumption';
import DateSwitch from './components/DateSwitch';
import OutPutBox from './components/OutputBox';

const ProductionUnitConsumptionBoard = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  // 初始值
  const [siderWidth, setSiderWidth] = useState(260);
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  // 拖拽状态
  const [dragStatus, setDragStatus] = useState(false);
  // 记录选中的 key
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  // 顶部选择综合能耗还是能源介质, 综合能耗的 id 为 1, 其他能源介质为其 id
  const [selectedBasePhysicalQuantityId, setSelectedBasePhysicalQuantityId] = useState<number | undefined>();

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 工作中心, 工站, 工序, 用能单元查询参数
  const generalQuery = useMemo(() => {
    console.log(selectedKeys);
    const key = (selectedKeys[0] as string) ?? '';

    return {
      // 用能单元 id
      energyUnitId: key.includes('energyUnit') ? key.split('-')[1] : undefined,
      // 工序工站关联id
      procedureStationLinkId:
        key.includes('workStation') || key.includes('energyUnit') ? key.split('-').at(-3) : undefined,
      // 工作中心和工序关联id
      centerProcedureLinkId: !key.includes('workCenter') ? key.split('-').at(-2) : undefined,
      // 必传工作中心 id
      workCenterId: key.split('-').at(-1)!,
    };
  }, [selectedKeys]);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Layout.Sider
          theme="light"
          className={styles.sider}
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            setSelectedBasePhysicalQuantityId={setSelectedBasePhysicalQuantityId}
          />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <RadioConsumption
            selectedKeys={selectedKeys}
            generalQuery={generalQuery}
            selectedBasePhysicalQuantityId={selectedBasePhysicalQuantityId}
            setSelectedBasePhysicalQuantityId={setSelectedBasePhysicalQuantityId}
          />
          <div className={styles.dateArea}>
            <DateSwitch />
            <Checkbox style={{ marginLeft: 16 }}>显示单耗数值</Checkbox>
          </div>
          <OutPutBox />
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default ProductionUnitConsumptionBoard;
