import React from 'react';
import { RoutePathItem } from '@maxtropy/components';
import { PermissionsType } from '@/common/permissionsConst';

import TemplateList from '@/pages/Template';
import TemplateDetail from '@/pages/Template/Detail';
import DAQDeployment from '@/pages/DaqDeployment';
import BatchUpdateEdge from '@/pages/Device/BatchUpdateEdge';
import DeviceList from '@/pages/Device/List';
import CreatePropertyInfo from '@/pages/Device/CreatePropertyInfo';
import CreateAttributeInfo from '@/pages/Device/CreateAttributeInfo';
import DeviceDetail from '@/pages/Device/Detail';
import EdgeTemplateDataCollection from '@/pages/DataCollection';
import BatchDeviceList from '@/pages/BatchDevice/List';
import CreateBatchDeviceProperty from '@/pages/BatchDevice/CreatePropertyInfo';
import CreateBatchDeviceAttribute from '@/pages/BatchDevice/CreateAttributeInfo';
import CreateBatchDeviceEdge from '@/pages/BatchDevice/CreateEdgeInfo';
import BatchDeviceDetail from '@/pages/BatchDevice/Detail';
import BatchBindRuleGroup from '@/pages/Device/BatchBindRuleGroup';
import BatchDeviceAttribute from '@/pages/Device/BatchAttribute';
import HistoryData from '@/pages/History';
import ScadaList from '@/pages/Scada';
import GatewayList from '@/pages/Gateway';
import CreateGateway from '@/pages/Gateway/Create';
import GatewayDetail from '@/pages/Gateway/Detail';
import GatewayBind from '@/pages/Gateway/Bind';
import ImplementList from '@/pages/Implement';
import ImplementAdjust from '@/pages/Implement/Adjust';
import ImplementDetail from '@/pages/Implement/Detail';
import EditProductionUnit from '@/pages/ProductionUnit/Edit';
import ProductionUitList from '@/pages/ProductionUnit/List';
import DeviceManage from '@/pages/DeviceManage';
import AlarmCreate from '@/pages/Alarm/Create/AlarmCreate';
import AlarmDetail from '@/pages/Alarm/Detail/AlarmDetail';
import AlarmRecordDetail from '@/pages/AlarmRecord/Detail';
import AlarmRecordList from '@/pages/AlarmRecord/List';
import CreateAlarmRulesGroup from '@/pages/AlarmRulesGroup/CreateAlarmRulesGroup';
import AlarmRulesGroupDetail from '@/pages/AlarmRulesGroup/Detail';

import EnergyConsumption from '@/pages/EnergyConsumption';

import AlarmPushList from '@/pages/AlarmPush/List';
import AlarmPushCreate from '@/pages/AlarmPush/Create';
import AlarmPushDetail from '@/pages/AlarmPush/Create/Detail';

import CreateReportStrategy from '@/pages/ReportStrategy/Create';
import ReportStrategyList from '@/pages/ReportStrategy/List';
import DetailReportStrategy from '@/pages/ReportStrategy/Detial';
import FcsList from '@/pages/FCS/List';
import CreateFcs from '@/pages/FCS/Create';
import FcsDetail from '@/pages/FCS/Detail';
import FcsLock from '@/pages/FCS/Lock';
import UETList from '@/pages/UET/List';
import UETCreate from '@/pages/UET/Form';
import UETEdit from '@/pages/UET/Edit';

import StatisticsConsumption from '@/pages/Statistics';
import AccountManage from '@/pages/Account';

import ElectricityAccountDevice from '@/pages/Account/Electricity/Device';
import ThermalAccountDevice from '@/pages/Account/Thermal/Device';

import Contract from '@/pages/Contract';
import ElectricRate from '@/pages/Contract/Electricity/ElectricRate';
import SalesContract from '@/pages/SalesContract';
import ShowSaleContractDetail from '@/pages/SalesContract/ShowSaleContractDetail';

import SaleClientList from '@/pages/Client/List';
import CreateSaleClient from '@/pages/Client/Create';
import DetailSaleClient from '@/pages/Client/Detail';
import SaleClientMeter from '@/pages/Client/Meter';

import SaleClientAccountList from '@/pages/ClientAccount/List';
import CreateSaleClientAccount from '@/pages/ClientAccount/Create';
import DetailSaleClientAccount from '@/pages/ClientAccount/Detail';
import RechargeOrRefund from '@/pages/ClientAccount/RechargeOrRefund';

import AccountBills from '@/pages/ClientAccount/AccountBills';
import Voucher from '@/pages/ClientAccount/Voucher';
import VoucherDetail from '@/pages/ClientAccount/Voucher/VoucherDetail';

import AddSaleContract from '@/pages/SalesContract/AddSaleContract';
import SettlementRules from '@/pages/SettlementRules';
import SettlementRulesDetails from '@/pages/SettlementRules/SettlementRulesDetails';
import CreateSettlementRules from '@/pages/SettlementRules/CreateSettlementRules';

import BalanceAlarmManagement from '@/pages/BalanceAlarm/BalanceAlarmManagement';
import CreateBalanceAlarm from '@/pages/BalanceAlarm/BalanceAlarmManagement/CreateBalanceAlarm';
import BalanceAlarmDetails from '@/pages/BalanceAlarm/BalanceAlarmManagement/BalanceAlarmDetails';
import ServerAlarmPushList from '@/pages/AlarmPush/ServerAlarmPushList';

import BalanceAlarmRecordList from '@/pages/BalanceAlarm/BalanceAlarmRecord/List';
import BalanceAlarmRecordDetail from '@/pages/BalanceAlarm/BalanceAlarmRecord/Detail';

import CreateServerAlarmPushConfig from '@/pages/ServerAlarmPushConfig/Create';
import ServerAlarmPushConfigDetail from '@/pages/ServerAlarmPushConfig/Detail';
import WorkOrder from '@/pages/AlarmRecord/WorkOrder';
import ElectricityBillChargeSchedule from '@/pages/ElectricityBill/ElectricityBillChargeSchedule';
import ElectricityBillDetail from '@/pages/ElectricityBill/Detail';
import ElectricityBill from '@/pages/ElectricityBill';

import ElectricityBillPayment from '@/pages/ElectricityBill/ElectricityBillPayment';
import ReportList from '@/pages/ReportList';
import ManualDebition from '@/pages/ClientAccount/ManualDebition';
import ManualCreateElectricityBill from '@/pages/ElectricityBill/ManualCreateElectricityBill';
import EnergyConsumptionConfig from '@/pages/EnergyConsumption/Config';
import ReportDownLoadList from '@/pages/ReportDownLoadList';
import AIReportList from '@/pages/AIReportList';
import Networking from '@/pages/Networking';
import AddNetworking from '@/pages/Networking/AddNetworking';
import DetailNetworking from '@/pages/Networking/DetailNetworking';
import DataMirror from '@/pages/Networking/DataMirror';
import Circuit from '@/pages/Circuit';
import CircuitCreate from '@/pages/Circuit/Create';
import DistributionCabinetManage from '@/pages/DistributionCabinetManage';
import CircuitDetail from '@/pages/Circuit/Detail';
import SettlementRulesBlog from '@/pages/SettlementRules/SettlementRulesBlog';
import OutputConfig from '@/pages/OutputConfig';
import TeamGroupManage from '@/pages/TeamGroupManage';
import ShiftCalendarConfiguration from '@/pages/ShiftCalendarConfiguration';
import AddToUnit from '@/pages/ShiftCalendarConfiguration/AddToUnit';
import DetailAppUnit from '@/pages/ShiftCalendarConfiguration/AddToUnit/components/DetailAppUnit';
import ShiftCalendar from '@/pages/ShiftCalendar';
import ShiftOP from '@/pages/ShiftCalendar/ShiftOp';
import EditShiftOP from '@/pages/ShiftCalendar/ShiftOp/EditShiftOp';
import ShiftView from '@/pages/ShiftCalendar/ShiftOp/ShiftView';

import SchedulePlan from '@/pages/SchedulePlan';
import SchedulePlanDetail from '@/pages/SchedulePlan/SchedulePlanDetail';
import OutputEntryManage from '@/pages/OutputEntryManage';
import OutputEntry from '@/pages/OutputEntryManage/OutputEntry';
import ShiftManage from '@/pages/ShiftManage';
import CreateEnergyWorking from '@/pages/UET/Edit/EnergyWorkingMedium/Create';
import EnergyWorkingDetail from '@/pages/UET/Edit/EnergyWorkingMedium/Detail';
import ViewEnergyWorkingTopo from '@/pages/UET/Edit/EnergyWorkingMedium/ViewEnergyWorkingTopo';
import ElectricityPurchaseRecordQuery from '@/pages/ElectricityPurchaseRecordQuery';
import ElectricityPurchaseRecordList from '@/pages/ElectricityPurchaseRecordQuery/ElectricityPurchaseRecordList';
import Quota from '@/pages/QualityMonitor/Quota';
import Configure from '@/pages/QualityMonitor/Configure';
import EnergyMediumDashBoard from '@/pages/WaterDashBoard';
import RateConfig from '@/pages/Contract/WorkingSubstanceContract/RateConfig';
import MeterRecord from '@/pages/SaleMeterReading/ElectricityRecord/MeterRecord';
import EnergyConsumptionCostConfig from '@/pages/EnergyConsumption/CostConfig';
import EnergyUnitKanban from '@/pages/EnergyUnitKanban';
import ElectricityRecord from '@/pages/SaleMeterReading/ElectricityRecord';
import ProductionEnergyConsumptionBoard from '@/pages/ProductionEnergyConsumptionBoard';
import CreateOutput from '@/pages/OutputConfig/CreateOutput';
import EnergyUnitMapping from '@/pages/EnergyUnitMapping';
import OutputDetail from '@/pages/OutputConfig/Detail';
import EnergyOverview from '@/pages/EnergyOverview';
import EnergyAssetsPortalConfig from '@/pages/EnergyAssetsPortalConfig';
import ProductionEnergyConsumptionBoardDemo from '@/pages/ProductionEnergyConsumptionBoardDemo';
import MultipleOverviewConfig from '@/pages/EnergyOverview/MultipleOverviewConfig';
import EnergyFlowAnalysis from '@/pages/EnergyFlowAnalysis';
import EnergyUnitsAlarmRules from '@/pages/EnergyUnitsAlarmRules';
import CraftProcess from '@/pages/CraftProcess';
import PvAlarmList from '@/pages/PvAlarm';
import PvAlarmCreate from '@/pages/PvAlarm/components/Create';
import PvAlarmDetail from '@/pages/PvAlarm/components/Detail';
import CreateEnergyAlarmRule from '@/pages/EnergyAlarmRule/Create';
import EnergyAlarmList from '@/pages/EnergyAlarmList';
import UnitConsumptionSetting from '@/pages/UnitConsumptionSetting';
import NewUnitConsumption from '@/pages/UnitConsumptionSetting/NewUnitConsumption';
import View from '@/pages/UnitConsumptionSetting/ViewPage'; //单耗设定的查看和复制
import Copy from '@/pages/UnitConsumptionSetting/CopyPage';

import CraftProcessCreate from '@/pages/CraftProcess/components/CraftProcessCreate';
import CraftProcessDetail from '@/pages/CraftProcess/components/CraftProcessDetail';
import EnergyAlarmRuleDetail from '@/pages/EnergyAlarmRule/Detail';
import EnergyAssetsSettlementsRules from '@/pages/EnergyAssetsSettlementsRules';

import DetailsEnergyAssetsSettlementsRules from '@/pages/EnergyAssetsSettlementsRules/DetailsEnergyAssetsSettlementsRules';
import CreateEnergyAssetsSettlementsRules from '@/pages/EnergyAssetsSettlementsRules/CreateEnergyAssetsSettlementsRules';
import EnergyAnalysisOptimize from '@/pages/EnergyAnalysisOptimize';
import AnalysisOptimize from '@/pages/EnergyAnalysisOptimize/AnalysisOptimize';
import AIDiagnosis from '@/pages/AIDiagnosis'; // 排产节能ai诊断
import AnalysisDetail from '@/pages/EnergyAnalysisOptimize/components/AnalysisDetail';
import EditEnergyAssetsSettlementRules from '@/pages/EnergyAssetsSettlementsRules/EditEnergyAssetsSettlementRules';
import EnergyAssetsBill from '@/pages/EnergyAssetsBill';
import CreateEnergyAssetsBill from '@/pages/EnergyAssetsBill/CreateEnergyAssetsBill';
import DetailEnergyAssetsBill from '@/pages/EnergyAssetsBill/DetailEnergyAssetsBill';
import EnergyMediaComparison from '@/pages/EnergyMediaComparison';
import EnergyMediaTopologyAnalysis from '@/pages/EnergyMediaTopologyAnalysis';
import CarbonAccountUnits from '@/pages/CarbonAccountUnits';
import CarbonDischargeTask from '@/pages/CarbonDischargeTask';
import CarbonYearDisChargePlan from '@/pages/CarbonYearDisChargePlan';
import CarbonAddYearPlan from '@/pages/CarbonYearDisChargePlan/CarbonAddYearPlan';
import FillingRules from '@/pages/FillingRules/List';
import FillingRulesCreateAndEdit from '@/pages/FillingRules/CreateAndEdit';
import CarbonEmissionReportingList from '@/pages/CarbonEmissionReporting/List';
import CarbonEmissionReportingEdit from '@/pages/CarbonEmissionReporting/Reporting';
import MonitorAccount from '@/pages/MonitorAccount';
import VideoMonitoring from '@/pages/VideoMonitoring';
import MonitorDeviceManage from '@/pages/MonitorAccount/components/MonitorDeviceManage';
import VideoDetail from '@/pages/VideoMonitoring/Detail';
import MeterReadingProject from '@/pages/MeterReadingProject';
import CreateMeterReading from '@/pages/MeterReadingProject/CreateMeterReading';
import DetailMeterReading from '@/pages/MeterReadingProject/Detail/DetailMeter';
import UniverseMeterQuery from '@/pages/UniverseMeterQuery';
import MeterProjectDetail from '@/pages/UniverseMeterQuery/components/MeterProjectDetail';
import AlarmList from '@/pages/Alarm/List';
import WorkCenterTopoPage from '@/pages/WorkCenterTopo';
import WaterUseConfig from '@/pages/WaterUseConfig';
import CarbonNewModel from '@/pages/ProductCarbonFootprint/NewModel';
import NormalInput from '@/pages/ProductCarbonFootprint/NewModel/components/NormalInput';
import EditInput from '@/pages/ProductCarbonFootprint/NewModel/components/EditInput';
import AddOutput from '@/pages/ProductCarbonFootprint/NewModel/components/AddOutput';
import CarbonCreateModel from '@/pages/ProductCarbonFootprint/NewModel/components/CreateModel';
import MaterielManagement from '@/pages/MaterielManagement';
import PlustekSankey from '@/pages/PlustekSankey';
import EnergyManagement from '@/pages/EnergyManagement';
import ModelManage from '@/pages/ProductCarbonFootprint';
import BomDetail from '@/pages/OutputConfig/Bom';
import Sceen from '@/pages/Temporary/Sceen';
import EnergyCost from '@/pages/Temporary/EnergyCost';
import EnergyBill from '@/pages/Temporary/EnergyBill';
import DeviceStatus from '@/pages/Temporary/DeviceStatus';
import EnergyTopo from '@/pages/Temporary/EnergyTopo';
import PowerLoss from '@/pages/Temporary/PowerLoss';
import GasLoss from '@/pages/Temporary/GasLoss';
import CarbonFootprintReporting from '@/pages/CarbonFootprintReporting';
import CarbonFootprintAddFiling from '@/pages/CarbonFootprintReporting/components/AddFilling';
import CarbonFootprintDetailFiling from '@/pages/CarbonFootprintReporting/Detail';
import MaterialWarehousingManagement from '@/pages/MaterialWarehousingManagement';
import DetailMaterialWarehousingManagement from '@/pages/MaterialWarehousingManagement/Detail';
import AddAccountingEntity from '@/pages/CarbonAccountUnits/AddAccountingEntity';
import PlustekEnergyPlan from '@/pages/PlustekEnergyPlan';
import EditPlan from '@/pages/PlustekEnergyPlan/components/EditPlan';
import SupplierManage from '@/pages/SupplierManage';
import CircuitLabels from '@/pages/Circuit/LabelsManage';
import AddProcess from '@/pages/ShiftCalendarConfiguration/AddToProcess';
import DetailAppProcess from '@/pages/ShiftCalendarConfiguration/AddToProcess/DetailAppProcess';
import EnergyConsumingUnits from '@/pages/EnergyConsumption/EnergyConsumingUnits';
import ProductiveProcessManage from '@/pages/ProductiveProcessManage';
import ProductionBeatDemo from '@/pages/ProductionBeatDemo';
import BaseLineManage from '@/pages/BaseLineManage';
import CreateBaseLine from '@/pages/BaseLineManage/CreateBaseLine';
import ViewBaseLine from '@/pages/BaseLineManage/ViewBaseLine';
import EnergyAlarmListDetail from '@/pages/EnergyAlarmList/EnergyAlarmListDetail';
import ProductionBeatConfig from '@/pages/ProductionBeatConfig';
import ProductionProcess from '@/pages/AppAnalyticsSubscription/ProductionProcess/List';
import CarbonFootprintOverview from '@/pages/CarbonFootprintOverview';
import BatchOperationApp from '@/pages/AppAnalyticsSubscription/ProductionProcess/BatchOpearion/BatchOperationApp';
import EnergyIntervalConfig from '@/pages/ProductionBeatConfig/EnergyIntervalConfig';
import ProductionBeatView from '@/pages/ProductionBeatView';
import GasMachineDashBoard from '@/pages/GasMachineDashBoard';
import DashBoardConfig from '@/pages/GasMachineDashBoard/DashboardConfig';
import IntrasiteTopology from '@/pages/UET/Edit/GasGenerationStation/IntrasiteTopology';
import MonitorDeviceDashBoard from '@/pages/MonitorDeviceDashBoard';
import SingleDeviceBoard from '@/pages/MonitorDeviceDashBoard/SingleDeviceBoard';

import WorkOrderManagement from '@/pages/TicketCenter/WorkOrderManagement/PlanList';
import WorkOrderManagementCreate from '@/pages/TicketCenter/WorkOrderManagement/CreatePlan';
import WorkOrderManagementDetail from '@/pages/TicketCenter/WorkOrderManagement/PlanDetail';
import EnergyOEEConfig from '@/pages/ProductionBeatConfig/EnergyOEEConfig';
import EquipmentProductPlan from '@/pages/EquipmentProductPlan';
import AddProductPlan from '@/pages/EquipmentProductPlan/AddProductPlan';
import OutputCategory from '@/pages/OutputCategory';
import HVACTopology from '@/pages/UET/Edit/HVACStation/HVACTopology';
import HVACNodeList from '@/pages/UET/Edit/HVACStation/NodeList';
import HVACNodeIndicatorConfig from '@/pages/UET/Edit/HVACStation/NodeIndicatorConfig';
import OEEApplication from '@/pages/OEE';
import MaterialFeedingManagement from '@/pages/MaterialFeedingManagement';
import ProductionReportingManagement from '@/pages/ProductionReportingManagement';
import WorkShopOverviewConfig from '@/pages/WorkShopOverviewConfig';
import MetricUnitConfiguration from '@/pages/MetricUnitConfiguration';
import HVACBoard from '@/pages/HVACBoard';
import HVACDashBoardConfig from '@/pages/HVACBoard/DashboardConfig';
import HVACDashBoard from '@/pages/HVACDashBoard';
import HVACDeviceDashBoard from '@/pages/HVACDashBoard/SingleDeviceBoard';
import EquipmentLedger from '@/pages/EquipmentLedger';
import EquipmentLedgerCreate from '@/pages/EquipmentLedger/Create';
import EquipmentLedgerDetail from '@/pages/EquipmentLedger/Detail';
import InputOutputInfo from '@/pages/InputOutputInfo';
import ProductionUnitConsumptionBoard from '@/pages/ProductionUnitConsumptionBoard';

const WaterUseOverview = React.lazy(
  () => import(/* webpackChunkName: "WaterUseOverview" */ `@/pages/WaterUseOverview`)
);

const mainRoutes: RoutePathItem[] = [
  {
    path: '/device/info/template',
    permission: PermissionsType.PATTRIBUTETEMPLATE,
    element: <TemplateList />,
  },
  {
    path: '/device/info/template/detail/:id',
    permission: PermissionsType.PATTRIBUTETEMPLATE,
    element: <TemplateDetail />,
  },
  {
    path: '/device/manage/device/daq-deployment',
    permission: PermissionsType.PDEVICEMANAGEMENT,
    element: <DAQDeployment />,
  },
  {
    path: '/device/manage/device/edge/update',
    permission: PermissionsType.PDEVICEMANAGEMENT,
    element: <BatchUpdateEdge />,
  },
  {
    path: '/device/manage/device',
    permission: PermissionsType.PDEVICEMANAGEMENT,
    element: <DeviceList />,
  },
  {
    path: '/device/manage/device/create',
    permission: PermissionsType.PCREATEDEVICE,
    element: <CreatePropertyInfo isNext />,
  },
  {
    path: '/device/manage/device/create/:id',
    permission: PermissionsType.PCREATEDEVICE,
    element: <CreatePropertyInfo isEdit isNext />,
  },
  {
    path: '/device/manage/device/:id/edit',
    permission: PermissionsType.PUPDATEDEVICEBASICINFO,
    element: <CreatePropertyInfo isEdit />,
  },
  {
    path: '/device/manage/device/:id/attribute/create',
    permission: PermissionsType.PCREATEDEVICE,
    element: <CreateAttributeInfo />,
  },
  {
    path: '/device/manage/device/:id/attribute/edit',
    permission: PermissionsType.PUPSERTDEVICEATTRIBUTEINFO,
    element: <CreateAttributeInfo isEdit />,
  },
  {
    path: '/device/manage/device/:id/detail',
    permission: PermissionsType.PDEVICEINFO,
    element: <DeviceDetail />,
  },
  {
    path: '/device/manage/device/:id/dataMining/new',
    permission: PermissionsType.PUPSERTDEVICEEDGEINFO,
    element: <EdgeTemplateDataCollection />,
  },
  {
    path: '/device/manage/device/:id/dataMining/edit',
    permission: PermissionsType.PUPSERTDEVICEEDGEINFO,
    element: <EdgeTemplateDataCollection isEdit />,
  },
  {
    path: '/device/manage/batch',
    permission: PermissionsType.PBATCHIMPORTDEVICE,
    element: <BatchDeviceList />,
  },
  {
    path: '/device/manage/batch/create',
    permission: PermissionsType.PCREATEIMPORTTEMPLATE,
    element: <CreateBatchDeviceProperty isNext />,
  },
  {
    path: '/device/manage/batch/create/:id',
    permission: PermissionsType.PCREATEIMPORTTEMPLATE,
    element: <CreateBatchDeviceProperty isNext isEdit />,
  },
  {
    path: '/device/manage/batch/:id/edit',
    permission: PermissionsType.PUPDATEBATCHDEVICEBASICINFO,
    element: <CreateBatchDeviceProperty isEdit />,
  },
  {
    path: '/device/manage/batch/:id/attribute/create',
    permission: PermissionsType.PUPSERTBATCHDEVICEATTRIBUTEINFO,
    element: <CreateBatchDeviceAttribute />,
  },
  {
    path: '/device/manage/batch/:id/attribute/edit',
    permission: PermissionsType.PUPSERTBATCHDEVICEATTRIBUTEINFO,
    element: <CreateBatchDeviceAttribute isEdit />,
  },
  {
    path: '/device/manage/batch/:id/dataMining/new',
    permission: PermissionsType.PUPSERTBATCHDEVICEEDGEINFO,
    element: <CreateBatchDeviceEdge />,
  },
  {
    path: '/device/manage/batch/:id/dataMining/edit',
    permission: PermissionsType.PUPSERTBATCHDEVICEEDGEINFO,
    element: <CreateBatchDeviceEdge />,
  },
  {
    path: '/device/manage/batch/:id/detail',
    permission: PermissionsType.PBATCHDEVICEINFO,
    element: <BatchDeviceDetail />,
  },
  {
    path: '/device/manage/device/rule-group/bind',
    permission: PermissionsType.PDEVICEMANAGEMENT,
    element: <BatchBindRuleGroup />,
  },
  {
    path: '/device/manage/device/attribute/batch',
    permission: PermissionsType.PDEVICEMANAGEMENT,
    element: <BatchDeviceAttribute />,
  },
  {
    path: '/data/history/device',
    permission: PermissionsType.PDEVICEHISTORY,
    element: <HistoryData />,
  },
  {
    path: '/device/scada/list',
    permission: PermissionsType.P_SCADALIST,
    element: <ScadaList />,
  },
  {
    path: '/device/config/gateway',
    permission: PermissionsType.PEDGEGATEWAYMANAGE,
    element: <GatewayList />,
  },
  {
    path: '/device/config/gateway/create',
    permission: PermissionsType.PCREATEGATEWAY,
    element: <CreateGateway />,
  },
  {
    path: '/device/config/gateway/update/:id',
    permission: PermissionsType.PUPDATEGATEWAY,
    element: <CreateGateway />,
  },
  {
    path: '/device/config/gateway/detail/:id',
    permission: PermissionsType.PEDGEGATEWAYMANAGE,
    element: <GatewayDetail />,
  },
  {
    path: '/device/config/gateway/update/:id/bind',
    permission: PermissionsType.PGATEWAYBINDDEVICE,
    element: <GatewayBind />,
  },
  {
    path: '/device/ou/authority',
    permission: PermissionsType.P_OU_IMPLEMENT,
    element: <ImplementList />,
  },
  {
    path: '/device/ou/authority/manage/:id',
    permission: PermissionsType.P_OU_IMPLEMENT_MANAGER,
    element: <ImplementAdjust />,
  },
  {
    path: '/device/ou/authority/detail/:id',
    permission: PermissionsType.P_OU_IMPLEMENT,
    element: <ImplementDetail />,
  },
  {
    path: '/device/ou/production-unit/:id/device',
    permission: PermissionsType.P_OU_DEVICE_MANAGE,
    element: <DeviceManage />,
  },
  {
    path: '/device/ou/production-unit',
    permission: PermissionsType.P_OU_MANAGE,
    element: <ProductionUitList />,
  },
  {
    path: '/device/ou/production-unit/new',
    permission: PermissionsType.P_CREATE_OU,
    element: <EditProductionUnit />,
  },
  {
    path: '/device/ou/production-unit/edit/:id',
    permission: PermissionsType.P_UPDATE_OU,
    element: <EditProductionUnit />,
  },
  {
    path: '/device/ou/production-unit/detail/:id',
    permission: PermissionsType.P_OU_MANAGE,
    element: <EditProductionUnit />,
  },
  {
    path: '/device/rule/list',
    permission: PermissionsType.P_RULE_MANAGE,
    element: <AlarmList />,
  },
  {
    path: '/device/rule/list/create',
    permission: PermissionsType.P_CREATE_RULE,
    element: <AlarmCreate />,
  },
  {
    path: '/device/rule/list/create/:id',
    permission: PermissionsType.P_UPDATE_RULE,
    element: <AlarmCreate isEdit />,
  },
  {
    path: '/device/rule/list/detail/:id',
    permission: PermissionsType.P_RULE_MANAGE,
    element: <AlarmDetail />,
  },
  {
    path: '/device/rule/list/group/create',
    permission: PermissionsType.P_CREATE_RULE_GROUP,
    element: <CreateAlarmRulesGroup />,
  },
  {
    path: '/device/rule/list/group/edit/:id',
    permission: PermissionsType.P_UPDATE_RULE_GROUP,
    element: <CreateAlarmRulesGroup isEdit />,
  },
  {
    path: '/device/rule/list/group/detail/:id',
    permission: PermissionsType.P_RULE_MANAGE,
    element: <AlarmRulesGroupDetail />,
  },
  {
    path: '/device/alarm/record',
    permission: PermissionsType.P_ALARM_LOG,
    element: <AlarmRecordList />,
  },
  {
    path: '/device/alarm/record/:id/create',
    element: <WorkOrder />,
  },
  {
    path: '/device/alarm/record/detail/:id',
    permission: PermissionsType.P_ALARM_LOG,
    element: <AlarmRecordDetail />,
  },
  {
    path: '/operation/balanceAlarm/record',
    permission: PermissionsType.P_BALANCESERVERRECORD,
    element: <BalanceAlarmRecordList />,
  },
  {
    path: '/operation/balanceAlarm/record/detail/:id',
    permission: PermissionsType.P_BALANCESERVERRECORD,
    element: <BalanceAlarmRecordDetail />,
  },
  {
    path: '/device/alarm/push',
    permission: PermissionsType.P_ALARM_PUSH_STRATEGY_MANAGE,
    element: <AlarmPushList />,
  },
  {
    path: '/device/alarm/serverPush',
    permission: PermissionsType.P_SERVERALARMPUSH,
    element: <ServerAlarmPushList />,
  },
  {
    path: '/device/alarm/push/create',
    permission: PermissionsType.P_CREATE_ALARM_PUSH_STRATEGY,
    element: <AlarmPushCreate />,
  },
  {
    path: '/device/alarm/push/edit/:id',
    permission: PermissionsType.P_UPDATE_ALARM_PUSH_STRATEGY,
    element: <AlarmPushCreate isEdit />,
  },
  {
    path: '/device/alarm/push/detail/:id',
    permission: PermissionsType.P_ALARM_PUSH_STRATEGY_MANAGE,
    element: <AlarmPushDetail />,
  },
  {
    path: '/device/alarm/serverPush/create',
    permission: PermissionsType.P_SERVERALARMPUSH,
    element: <CreateServerAlarmPushConfig />,
  },
  {
    path: '/device/alarm/serverPush/edit/:id',
    permission: PermissionsType.P_SERVERALARMPUSH,
    element: <CreateServerAlarmPushConfig isEdit />,
  },
  {
    path: '/device/alarm/serverPush/detail/:id',
    permission: PermissionsType.P_SERVERALARMPUSH,
    element: <ServerAlarmPushConfigDetail />,
  },
  {
    path: '/data/report/strategy',
    permission: PermissionsType.P_REPORT_STRATEGY,
    element: <ReportStrategyList />,
  },
  {
    path: '/data/report/list',
    permission: PermissionsType.P_REPORT_LIST,
    element: <ReportList />,
  },
  {
    path: '/data/AIReport/list',
    permission: PermissionsType.P_ARTIFICALINTELLIGENCEREPORT,
    element: <AIReportList />,
  },
  {
    path: '/data/report/strategy/create',
    permission: PermissionsType.P_CREATE_REPORT_STRATEGY,
    element: <CreateReportStrategy />,
  },
  {
    path: '/data/report/strategy/edit/:id',
    permission: PermissionsType.P_UPDATE_REPORT_STRATEGY,
    element: <CreateReportStrategy isEdit />,
  },
  {
    path: '/data/report/strategy/detail/:id',
    permission: PermissionsType.P_REPORT_STRATEGY,
    element: <DetailReportStrategy />,
  },
  {
    path: '/device/fcs/list',
    permission: PermissionsType.P_FCSPAGE,
    element: <FcsList />,
  },
  {
    path: '/device/fcs/list/create',
    permission: PermissionsType.P_CREATEFCS,
    element: <CreateFcs />,
  },
  {
    path: '/device/fcs/list/edit/:id',
    permission: PermissionsType.P_UPDATEFCS,
    element: <CreateFcs isEdit />,
  },
  {
    path: '/device/fcs/list/detail/:id',
    permission: PermissionsType.P_FCSPAGE,
    element: <FcsDetail />,
  },
  {
    path: '/device/fcs/list/lock/:id',
    permission: PermissionsType.P_PASSCODEWEB,
    element: <FcsLock />,
  },
  {
    path: '/energy/basic/uet',
    permission: PermissionsType.P_UETMANAGE,
    element: <UETList />,
  },
  {
    path: '/energy/basic/uet/create',
    permission: PermissionsType.P_CREATEUET,
    element: <UETCreate />,
  },
  {
    path: '/energy/basic/uet/edit/:id/:key',
    permission: PermissionsType.P_CREATEUET,
    element: <UETEdit />,
  },
  {
    path: '/energy/basic/uet/edit/:id/:key/createEnergyWorkingTopo',
    permission: PermissionsType.P_EMTMANAGE,
    element: <CreateEnergyWorking />,
  },
  {
    path: '/energy/basic/uet/edit/:id/:key/editEnergyWorkingTopo/:energyId',
    permission: PermissionsType.P_EMTMANAGE,
    element: <CreateEnergyWorking isEdit />,
  },
  {
    path: '/energy/basic/uet/edit/:id/:key/viewEnergyWorkingTopo/:energyId',
    permission: PermissionsType.P_EMTMANAGE,
    element: <ViewEnergyWorkingTopo />,
  },
  {
    path: '/energy/basic/uet/edit/:id/:key/detailEnergyWorkingTopo/:energyId',
    permission: PermissionsType.P_EMTMANAGE,
    element: <EnergyWorkingDetail />,
  },
  {
    path: '/energy/basic/uet/edit/:id/*',
    permission: PermissionsType.P_CREATEUET,
    element: <UETEdit />,
  },
  {
    path: '/energy/basic/uet/detail/:id/:key',
    permission: PermissionsType.P_UETINFO,
    element: <UETEdit isDetail />,
  },
  {
    path: '/energy/basic/uet/detail/:id/*',
    permission: PermissionsType.P_UETINFO,
    element: <UETEdit isDetail />,
  },
  {
    path: '/energy/basic/uet/hvacNodeList/:hvacId/:stationId',
    permission: PermissionsType.P_HVAC_STATION_PROPERTY_LIST,
    element: <HVACNodeList />,
  },
  {
    path: '/energy/basic/uet/hvacNodeIndicatorConfig/:hvacId/:stationId/:id',
    permission: PermissionsType.P_HVAC_STATION_PROPERTY_CONFIG,
    element: <HVACNodeIndicatorConfig />,
  },
  {
    path: '/operation/energy/consumption',
    permission: PermissionsType.P_ACCOUNT_MANAGE,
    element: <StatisticsConsumption />,
  },
  {
    path: '/operation/energy/account',
    permission: PermissionsType.P_ACCOUNT_MANAGE,
    element: <AccountManage />,
  },
  {
    path: '/operation/energy/account/electricity/device/:id',
    permission: PermissionsType.P_ACCOUNT_MANAGE,
    element: <ElectricityAccountDevice />,
  },
  {
    path: '/operation/energy/account/thermal/device/:id',
    permission: PermissionsType.P_ACCOUNT_MANAGE,
    element: <ThermalAccountDevice />,
  },
  {
    path: '/operation/energy/contract',
    permission: PermissionsType.P_CONTRACT_MANAGE,
    element: <Contract />,
  },
  {
    path: '/operation/energy/contract/electric-rate/edit/:id',
    permission: PermissionsType.P_CONTRACT_MANAGE,
    element: <ElectricRate />,
  },
  {
    path: '/operation/energy/contract/electric-rate/detail/:id',
    permission: PermissionsType.P_CONTRACT_MANAGE,
    element: <ElectricRate disabled />,
  },
  {
    path: '/operation/salesContract/electricity',
    permission: PermissionsType.P_PROP_MANAGE_CONTRACT_LIST,
    element: <SalesContract />,
  },
  {
    path: '/operation/salesContract/electricity/detail/:id',
    permission: PermissionsType.P_PROP_MANAGE_CONTRACT_LIST,
    element: <ShowSaleContractDetail />,
  },
  {
    path: '/operation/salesContract/electricity/edit/:id',
    permission: PermissionsType.P_PROP_MANAGE_CONTRACT_LIST,
    element: <AddSaleContract isEdit />,
  },
  {
    path: '/operation/salesContract/electricity/create',
    permission: PermissionsType.P_PROP_MANAGE_CONTRACT_LIST,
    element: <AddSaleContract />,
  },
  {
    path: '/operation/client-related/client',
    permission: PermissionsType.P_CLIENT_MANAGE,
    element: <SaleClientList />,
  },
  {
    path: '/operation/client-related/client/create',
    permission: PermissionsType.P_CLIENT_MANAGE,
    element: <CreateSaleClient />,
  },
  {
    path: '/operation/client-related/client/edit/:id',
    permission: PermissionsType.P_CLIENT_MANAGE,
    element: <CreateSaleClient />,
  },
  {
    path: '/operation/client-related/client/detail/:id',
    permission: PermissionsType.P_CLIENT_MANAGE,
    element: <DetailSaleClient />,
  },
  {
    path: '/operation/client-related/client/meter/edit/:id',
    permission: PermissionsType.P_CLIENT_MANAGE,
    element: <SaleClientMeter />,
  },
  {
    path: '/operation/client-related/client/meter/detail/:id',
    permission: PermissionsType.P_CLIENT_MANAGE,
    element: <SaleClientMeter disabled />,
  },
  {
    path: '/operation/client-related/client-account',
    permission: PermissionsType.P_CLIENT_ACCOUNT,
    element: <SaleClientAccountList />,
  },
  {
    path: '/operation/client-related/client-account/create',
    permission: PermissionsType.P_CLIENT_ACCOUNT,
    element: <CreateSaleClientAccount />,
  },
  {
    path: '/operation/client-related/client-account/edit/:id',
    permission: PermissionsType.P_CLIENT_ACCOUNT,
    element: <CreateSaleClientAccount />,
  },
  {
    path: '/operation/client-related/client-account/detail/:id',
    permission: PermissionsType.P_CLIENT_ACCOUNT,
    element: <DetailSaleClientAccount />,
  },
  {
    path: '/operation/client-related/client-account/recharge-refund/:id',
    permission: PermissionsType.B_RECHARGE_REFUND,
    element: <RechargeOrRefund />,
  },
  {
    path: '/operation/client-related/client-account/manual-debition/:id',
    permission: PermissionsType.B_MANUALDEDUCTION,
    element: <ManualDebition />,
  },
  {
    path: '/operation/client-related/client-account/bills/:id',
    permission: PermissionsType.P_CLIENT_ACCOUNT,
    element: <AccountBills />,
  },
  {
    path: '/operation/client-related/client-account/voucher/:id',
    permission: PermissionsType.P_CLIENT_ACCOUNT,
    element: <Voucher />,
  },
  {
    path: '/operation/client-related/client-account/voucher/voucherDetail/:id',
    permission: PermissionsType.P_CLIENT_ACCOUNT,
    element: <VoucherDetail />,
  },
  {
    path: '/operation/settlementRules/electricity',
    permission: PermissionsType.P_ELECTRICEXTERNALRULE,
    element: <SettlementRules />,
  },
  {
    path: '/operation/settlementRules/electricity/details/:id',
    permission: PermissionsType.P_ELECTRICEXTERNALRULE,
    element: <SettlementRulesDetails />,
  },
  {
    path: '/operation/settlementRules/electricity/create',
    permission: PermissionsType.P_ELECTRICEXTERNALRULE,
    element: <CreateSettlementRules />,
  },
  {
    path: '/operation/settlementRules/electricity/edit/:id',
    permission: PermissionsType.P_ELECTRICEXTERNALRULE,
    element: <CreateSettlementRules isEdit />,
  },
  {
    path: '/operation/settlementRules/electricity/blog',
    permission: PermissionsType.P_CHANGELOGLATEST,
    element: <SettlementRulesBlog />,
  },
  {
    path: '/operation/meterReading/electricityRecord',
    permission: PermissionsType.P_ELECTRIC_METER_READING_LOG,
    element: <ElectricityRecord />,
  },
  {
    path: '/operation/balanceAlarm/management',
    permission: PermissionsType.P_BALANCESERVERALARMMANAGE,
    element: <BalanceAlarmManagement />,
  },
  {
    path: '/operation/balanceAlarm/management/create',
    permission: PermissionsType.P_BALANCESERVERALARMMANAGE,
    element: <CreateBalanceAlarm />,
  },
  {
    path: '/operation/balanceAlarm/management/mutiCreate',
    permission: PermissionsType.P_BALANCESERVERALARMMANAGE,
    element: <CreateBalanceAlarm isMuti />,
  },
  {
    path: '/operation/balanceAlarm/management/edit/:id',
    permission: PermissionsType.P_BALANCESERVERALARMMANAGE,
    element: <CreateBalanceAlarm isEdit />,
  },
  {
    path: '/operation/balanceAlarm/management/details/:id',
    permission: PermissionsType.P_BALANCESERVERALARMMANAGE,
    element: <BalanceAlarmDetails />,
  },
  {
    path: '/operation/settlementRules/electricityBill',
    permission: PermissionsType.P_ELECTRIC_BILL_LIST,
    element: <ElectricityBill />,
  },
  {
    path: '/operation/settlementRules/electricityBill/detail/:id',
    permission: PermissionsType.P_ELECTRIC_BILL_LIST,
    element: <ElectricityBillDetail />,
  },
  {
    path: '/operation/settlementRules/electricityBill/electricityBillChargeSchedule/:id',
    permission: PermissionsType.P_ELECTRIC_BILL_DETAIL,
    element: <ElectricityBillChargeSchedule />,
  },
  {
    path: '/operation/settlementRules/electricityBill/payment/:id',
    permission: PermissionsType.P_ELECTRIC_BILL_PAY,
    element: <ElectricityBillPayment />,
  },
  {
    path: '/operation/settlementRules/electricityBill/manualCreate',
    permission: PermissionsType.P_GENERATE_ELECTRIC_BILL_MANUAL,
    element: <ManualCreateElectricityBill />,
  },
  {
    path: '/operation/settlementRules/electricityBill/manualAgainCreate',
    element: <ManualCreateElectricityBill />,
  },
  {
    path: '/energy/consumption/analysis',
    permission: PermissionsType.P_ENERGY_CONSUMPTION_ANALYSIS_UNIT,
    element: <EnergyConsumption />,
  },
  {
    path: '/energy/unit/kanban',
    permission: PermissionsType.P_CAN_USE_KANBAN_SEPARATELY,
    element: <EnergyUnitKanban />,
  },
  {
    path: '/energy/teamShift/teamGroupManage',
    permission: PermissionsType.P_WORKTEAMMANAGE,
    element: <TeamGroupManage />,
  },
  {
    path: '/energy/teamShift/shiftCalendar',
    permission: PermissionsType.P_SHIFTCALENDAR_MANAGE,
    element: <ShiftCalendar />,
  },
  {
    path: '/energy/teamShift/shiftManage',
    permission: PermissionsType.P_WORKSHIFT_MANAGE,
    element: <ShiftManage />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/configuration/:id',
    permission: PermissionsType.P_WORKSHIFTCALENDARUSECONFIG,
    element: <ShiftCalendarConfiguration />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/configuration/add',
    element: <AddToUnit />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/configuration/edit/:id',
    element: <AddToUnit isEdit={true} />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/process/add',
    element: <AddProcess />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/process/edit/:id',
    element: <AddProcess isEdit={true} />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/process/detail/:id',
    element: <DetailAppProcess />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/configuration/detail/:id',
    element: <DetailAppUnit />,
  },
  {
    path: '/energy/consumption/analysis/config/:id',
    permission: PermissionsType.PEDGEGATEWAYMANAGE,
    element: <EnergyConsumptionConfig />,
  },
  {
    path: '/energy/consumption/analysis/cost-config/:id',
    permission: PermissionsType.P_COST_ALLOCATION,
    element: <EnergyConsumptionCostConfig />,
  },
  {
    path: '/energy/output/config',
    permission: PermissionsType.P_BELTLINEOUTPUTCONFIG,
    element: <OutputConfig />,
  },
  {
    path: '/energy/output/config/add',
    permission: PermissionsType.P_BELTLINEOUTPUTCONFIG,
    element: <CreateOutput />,
  },
  {
    path: '/energy/output/config/update/:id',
    permission: PermissionsType.P_BELTLINEOUTPUTCONFIG,
    element: <CreateOutput />,
  },
  {
    path: '/energy/output/config/detail/:id',
    permission: PermissionsType.P_BELTLINEOUTPUTCONFIG,
    element: <OutputDetail />,
  },
  {
    path: '/energy/output/config/bom/:id',
    permission: '',
    element: <BomDetail />,
  },
  {
    path: '/energy/schedule/plan',
    permission: PermissionsType.P_WORKSHIFTAPPLYMANAGE,
    element: <SchedulePlan />,
  },
  {
    path: '/energy/schedule/plan/detail/:id',
    permission: PermissionsType.P_WORKSHIFTAPPLYDETAIL,
    element: <SchedulePlanDetail />,
  },
  {
    path: '/energy/output/outputEntryList',
    permission: PermissionsType.P_BELTLINEOUTPUTENTRY,
    element: <OutputEntryManage />,
  },
  {
    path: '/energy/output/outputEntryList/entry/:id',
    permission: PermissionsType.P_BELTLINEOUTPUTENTRYDETAIL,
    element: <OutputEntry />,
  },
  {
    path: '/data/report/reportCheck',
    permission: PermissionsType.P_ANALYSISREPORTMANAGEDETAIL,
    element: <ReportDownLoadList />,
  },
  {
    path: '/device/networking/networkingList',
    permission: PermissionsType.P_NETWORK_LIST,
    element: <Networking />,
  },
  {
    path: '/device/networking/networkingList/add',
    permission: PermissionsType.P_NETWORK_NEW,

    element: <AddNetworking />,
  },
  {
    path: '/device/networking/networkingList/edit/:id',
    permission: PermissionsType.P_NETWORK_EDIT,

    element: <AddNetworking isEdit={true} />,
  },
  {
    path: '/device/networking/networkingList/detail/:id',
    permission: PermissionsType.P_NETWORK_DETAIL,
    element: <DetailNetworking />,
  },
  {
    path: '/device/networking/networkingList/dataMirror/:id',
    element: <DataMirror />,
  },
  {
    path: '/dc/circuit/circuitManage',
    permission: PermissionsType.P_CIRCUITDCLIST,
    element: <Circuit />,
  },
  {
    path: '/dc/circuit/labelsManage',
    permission: PermissionsType.P_CIRCUIT_LABEL_PAGE,
    element: <CircuitLabels />,
  },
  {
    path: '/dc/circuit/circuitManage/create',
    permission: PermissionsType.P_CIRCUITDCCREATE,
    element: <CircuitCreate />,
  },
  {
    path: '/dc/circuit/circuitManage/edit/:id',
    permission: PermissionsType.P_CIRCUITDCEDIT,
    element: <CircuitCreate isEdit={true} />,
  },
  {
    path: '/dc/circuit/circuitManage/detail/:id',
    permission: PermissionsType.P_CIRCUITDCDETAIL,
    element: <CircuitDetail />,
  },
  {
    path: '/dc/circuit/distributionCabinetManage',
    permission: PermissionsType.P_DISTRIBUTIONCABINETMANAGE,
    element: <DistributionCabinetManage />,
  },
  {
    path: '/energyAssets/energyMediumDashboard/water',
    permission: PermissionsType.P_WATERDB,
    element: <EnergyMediumDashBoard sceneId={500} />,
  },
  {
    path: '/energyAssets/energyMediumDashboard/gas',
    permission: PermissionsType.P_GASDB,
    element: <EnergyMediumDashBoard sceneId={501} />,
  },
  {
    path: '/energyAssets/energyMediumDashboard/hotSteam',
    permission: PermissionsType.P_STEAMDB,
    element: <EnergyMediumDashBoard sceneId={502} />,
  },
  {
    path: '/energyAssets/energyMediumDashboard/compressedAir',
    permission: PermissionsType.P_AIRCOMPRESSORBOARD,
    element: <EnergyMediumDashBoard sceneId={503} />,
  },
  {
    path: '/energyAssets/energyMediumDashboard/industrialGas',
    permission: PermissionsType.P_INDUSTRIALGASBOARD,
    element: <EnergyMediumDashBoard sceneId={504} />,
  },
  {
    path: '/energyAssets/energyMediumDashboard/heatingVentilationAndAirConditioning',
    permission: PermissionsType.P_HVACBOARD,
    element: <EnergyMediumDashBoard sceneId={505} />,
  },
  {
    path: '/energyAssets/energyMediumDashboard/industrialLiquids',
    permission: PermissionsType.P_INDUSTRIALLIQUIDBOARD,
    element: <EnergyMediumDashBoard sceneId={506} />,
  },
  // 工质监测
  {
    path: '/quality/monitor/quota',
    element: <Quota />,
    permission: PermissionsType.P_INDICATOR_CONFIG,
  },
  {
    path: '/quality/monitor/quota/:id/:type',
    element: <Configure />,
    permission: PermissionsType.P_INOUT_CONFIG,
  },

  {
    path: '/assetsOperation/elePurEnergyQuery/elePurEleRecordQuery',
    permission: PermissionsType.P_PURCHASER_ELETRICITY_RECORD,
    element: <ElectricityPurchaseRecordQuery />,
  },

  {
    path: '/assetsOperation/elePurEnergyQuery/elePurEleRecordQuery/list',
    permission: PermissionsType.P_PURCHASER_ELETRICITY_RECORD,
    element: <ElectricityPurchaseRecordList />,
  },
  {
    path: '/operation/energy/contract/work-substance-rate/:id',
    permission: PermissionsType.B_RATEALLOCATION,
    element: <RateConfig />,
  },
  {
    path: '/operation/meterReading/electricityRecord/record',
    permission: PermissionsType.P_ELECTRIC_METER_READING_LOG,
    element: <MeterRecord />,
  },
  {
    path: '/productionManagement/report/board',
    permission: PermissionsType.P_PRODUCTION_ENERGY_CONSUMPTION_KANBAN,
    element: <ProductionEnergyConsumptionBoard />,
  },
  {
    path: '/productionManagement/report/boarddemo',
    permission: PermissionsType.P_PRODUCTION_ENERGY_CONSUMPTION_KANBAN_DEMO,
    element: <ProductionEnergyConsumptionBoardDemo />,
  },
  {
    path: '/productionManagement/production/energyUnitMapping',
    permission: PermissionsType.P_ENERGYCONSUMPTIONUNITMAPP,
    element: <EnergyUnitMapping />,
  },
  // 单耗设定
  {
    path: '/productionManagement/production/unitConsumptionSetting',
    permission: PermissionsType.P_CONSUMPTIONSETTING,
    element: <UnitConsumptionSetting />,
  },
  {
    path: '/productionManagement/production/unitConsumptionSetting/create',
    element: <NewUnitConsumption />,
  },
  {
    path: '/productionManagement/production/unitConsumptionSetting/:id',
    element: <NewUnitConsumption isEdit />,
  },
  {
    path: '/productionManagement/production/unitConsumptionSetting/view/:id',
    element: <View />,
  },
  {
    path: '/productionManagement/production/unitConsumptionSetting/copy/:id',
    element: <Copy />,
  },
  // 排产节能AI诊断
  {
    path: '/productionManagement/productionScheduling/aiDiagnosis',
    permission: PermissionsType.P_PRODUCTIONAI,
    element: <AIDiagnosis />,
  },
  {
    path: '/energy/analysisGroup/overview',
    permission: PermissionsType.P_ENERGYOVERVIEW,
    element: <EnergyOverview />,
  },
  {
    path: '/energy/water/overview',
    permission: PermissionsType.P_WATER_OVERVIEW,
    element: <WaterUseOverview />,
  },
  {
    path: '/energy/water/overview/config',
    permission: PermissionsType.P_WATER_OVERVIEW_CONFIG,
    element: <WaterUseConfig />,
  },
  {
    path: '/energy/analysisGroup/overview/config',
    permission: PermissionsType.P_ENERGYOVERVIEWCONFIGURE,
    element: <MultipleOverviewConfig />,
  },
  // 能源资产门户配置

  {
    path: '/energy/assets/portalConfig',
    permission: PermissionsType.P_CONFIGPORTAL,
    element: <EnergyAssetsPortalConfig />,
  },
  // 用能单元报警列表
  {
    path: '/energy/analysisGroup/energyAlarmList',
    permission: PermissionsType.P_ENERGYCONSUMPTIONALARMLIST,
    element: <EnergyAlarmList />,
  },
  {
    path: '/energy/analysisGroup/energyAlarmList/detail/:id',
    permission: PermissionsType.P_ENERGYCONSUMPTIONALARMLIST,
    element: <EnergyAlarmListDetail />,
  },
  // 用能单元报警规则
  {
    path: '/energyUnits/Alarm/rules',
    permission: PermissionsType.P_ENERGYCONSUMPTIONALARMRULEPAGE,
    element: <EnergyUnitsAlarmRules />,
  },
  {
    path: '/energyUnits/Alarm/rules/create',
    permission: PermissionsType.P_CREATERULEPAGE,
    element: <CreateEnergyAlarmRule />,
  },
  {
    path: '/energyUnits/Alarm/rules/create/:id',
    permission: PermissionsType.P_EDITRULEPAGE,
    element: <CreateEnergyAlarmRule isEdit />,
  },
  {
    path: '/energyUnits/Alarm/rules/detail/:id',
    permission: PermissionsType.P_RULEPAGE,
    element: <EnergyAlarmRuleDetail />,
  },
  // 精益能流分析
  {
    path: '/energy/flow/analysis',
    permission: PermissionsType.P_FLOW_ANALYSIS,
    element: <EnergyFlowAnalysis />,
  },

  // 工艺流程
  {
    path: '/productionEnergy/craftProcess/management',
    permission: PermissionsType.P_PROCESSFLOW,
    element: <CraftProcess />,
  },
  {
    path: '/productionEnergy/craftProcess/management/create',
    permission: PermissionsType.P_PROCESSFLOWCONFIG,
    element: <CraftProcessCreate />,
  },
  {
    path: '/productionEnergy/craftProcess/management/create/:id',
    permission: PermissionsType.P_PROCESSFLOWCONFIG,
    element: <CraftProcessCreate isEdit />,
  },
  {
    path: '/productionEnergy/craftProcess/management/detail/:id',
    permission: PermissionsType.P_PROCESSFLOWCONFIG,
    element: <CraftProcessDetail />,
  },
  {
    path: '/pvOps/server/alarm',
    permission: PermissionsType.P_SERVERALARMRULE,
    element: <PvAlarmList />,
  },
  {
    path: '/pvOps/server/alarm/create',
    permission: PermissionsType.P_SERVERALARMCREATE,
    element: <PvAlarmCreate />,
  },
  {
    path: '/pvOps/server/alarm/edit/:id',
    permission: PermissionsType.P_SERVERALARMUPDATE,
    element: <PvAlarmCreate isEdit />,
  },
  {
    path: '/pvOps/server/alarm/detail/:id',
    permission: PermissionsType.P_SERVERALARMDETAIL,
    element: <PvAlarmDetail />,
  },
  // 能源资产营销
  {
    path: '/operation/settlementRules/energyAssetsRules',
    permission: PermissionsType.P_ENERGYPROPERTYRULE,
    element: <EnergyAssetsSettlementsRules />,
  },
  {
    path: '/operation/settlementRules/energyAssetsRules/details/:id',
    permission: '',
    element: <DetailsEnergyAssetsSettlementsRules />,
  },
  {
    path: '/operation/settlementRules/energyAssetsRules/create',
    permission: PermissionsType.P_ENERGYPROPERTYRULECREATE,
    element: <CreateEnergyAssetsSettlementsRules />,
  },
  {
    path: '/operation/settlementRules/energyAssetsRules/edit/:id',
    permission: PermissionsType.P_ENERGYPROPERTYRULEEDIT,
    element: <EditEnergyAssetsSettlementRules />,
  },
  {
    path: '/operation/settlementRules/energyAssetsRules/copy/:id',
    permission: '',
    element: <CreateEnergyAssetsSettlementsRules isCopy />,
  },
  // 结算单
  {
    path: '/operation/settlements/energyAssetsBill',
    permission: PermissionsType.P_ENERGYPROPERTYSETTLEMENTSTATEMENT,
    element: <EnergyAssetsBill />,
  },
  {
    path: '/operation/settlements/energyAssetsBill/create',
    permission: PermissionsType.B_ENERGYPROPERTYSETTLEMENTSTATEMENTMANALCHECK,
    element: <CreateEnergyAssetsBill />,
  },
  {
    path: '/operation/settlements/energyAssetsBill/detail/:id',
    permission: PermissionsType.P_ENERGYPROPERTYSETTLEMENTSTATEMENTCHECKDETAIL,
    element: <DetailEnergyAssetsBill />,
  },

  {
    path: '/energy/analysis/optimizeConfig',
    permission: PermissionsType.P_OPTIMIZEPROJECTCONFIG,
    element: <EnergyAnalysisOptimize />,
  },
  {
    path: '/energy/analysis/optimizeAnalysis',
    permission: PermissionsType.P_OPTIMIZEANALYSIS,
    element: <AnalysisOptimize />,
  },
  {
    path: '/energy/analysis/optimizeAnalysis/detail/:id',
    permission: PermissionsType.P_OPTIMIZEANALYSISPAGE,
    element: <AnalysisDetail />,
  },
  // 能源介质对比
  {
    path: '/energy/media/comparison',
    permission: PermissionsType.P_ENERGYMEDIUMCOMPARE,
    element: <EnergyMediaComparison />,
  },
  // 能源介质分析-拓扑分析
  {
    path: '/energyMedia/topology/analysis',
    permission: PermissionsType.P_PROCESSANALYSIS,
    element: <EnergyMediaTopologyAnalysis />,
  },
  {
    path: '/carbonManage/mainAccount/carbonAccountUnits',
    permission: PermissionsType.P_CARBONACCOUNTINGUNIT,
    element: <CarbonAccountUnits />,
  },
  {
    path: '/carbonManage/mainAccount/yearDisChargePlan',
    permission: PermissionsType.P_CARBONEMISSIONPLAN,
    element: <CarbonYearDisChargePlan />,
  },
  {
    path: '/carbonManage/mainAccount/yearDisChargePlan/add',
    permission: PermissionsType.P_CARBONEMISSIONPLANSAVE,
    element: <CarbonAddYearPlan />,
  },
  {
    path: '/carbonManage/mainAccount/yearDisChargePlan/edit/:id',
    permission: PermissionsType.P_CARBONEMISSIONPLANEDIT,
    element: <CarbonAddYearPlan isEdit={true} />,
  },
  {
    path: '/carbonManage/mainAccount/yearDisChargePlan/detail/:id',
    permission: PermissionsType.P_CARBONEMISSIONPLANDETAIL,
    element: <CarbonAddYearPlan isDetail={true} />,
  },
  {
    path: '/carbonManage/dischargeQuestion/dischargeTask',
    permission: PermissionsType.P_CARBONINVENTORYTASK,
    element: <CarbonDischargeTask />,
  },
  {
    path: '/corporateCarbonInvestigation/setUp/automaticReportingRuleManagement',
    permission: PermissionsType.P_CARBONEMISSIONAUTOFILLINGRULE,
    element: <FillingRules />,
  },
  {
    path: '/corporateCarbonInvestigation/setUp/automaticReportingRuleManagement/create',
    permission: PermissionsType.P_CARBONEMISSIONAUTOFILLINGRULE,
    element: <FillingRulesCreateAndEdit />,
  },
  {
    path: '/corporateCarbonInvestigation/setUp/automaticReportingRuleManagement/edit/:id',
    permission: PermissionsType.P_CARBONEMISSIONAUTOFILLINGRULE,
    element: <FillingRulesCreateAndEdit edit />,
  },
  {
    path: '/leanCarbonManagement/carbonEmissionInventory/carbonEmissionReporting',
    permission: PermissionsType.P_CARBONEMISSIONFILLING,
    element: <CarbonEmissionReportingList />,
  },
  {
    path: '/leanCarbonManagement/carbonEmissionInventory/carbonEmissionReporting/edit/:id',
    permission: PermissionsType.P_CARBONEMISSIONFILLING,
    element: <CarbonEmissionReportingEdit />,
  },
  // 监控账户
  {
    path: '/monitor/account/configList',
    permission: PermissionsType.P_SURVEILLANCEACCOUNTMANAGE,
    element: <MonitorAccount />,
  },
  // 管理监控设备
  {
    path: '/monitor/account/configList/:id',
    permission: PermissionsType.P_SURVEILLANCEACCOUNTMANAGE,
    element: <MonitorDeviceManage />,
  },
  // 视频监控列表
  {
    path: '/monitor/video/list',
    permission: PermissionsType.P_VIDEOSURVEILLANCELIST,
    element: <VideoMonitoring />,
  },
  {
    path: '/monitor/video/list/detail/:id',
    permission: PermissionsType.P_VIDEOSURVEILLANCELIST,
    element: <VideoDetail />,
  },
  {
    path: '/energy/analysis/universeMeterQuery',
    permission: PermissionsType.P_GLOBALMETERREADING,
    element: <UniverseMeterQuery />,
  },
  {
    path: '/energy/analysis/universeMeterQuery/detail/:id',
    permission: PermissionsType.P_GLOBALMETERREADINGDETAIL,
    element: <MeterProjectDetail />,
  },
  {
    path: '/energy/consumption/meterReadingProject',
    permission: PermissionsType.P_GLOBALMETERREADINGSETTING,
    element: <MeterReadingProject />,
  },
  {
    path: '/energy/consumption/meterReadingProject/createMeterReading',
    permission: '',
    element: <CreateMeterReading />,
  },
  {
    path: '/energy/consumption/meterReadingProject/detail/:id',
    permission: PermissionsType.P_GLOBALMETERREADINGPROJECT,
    element: <DetailMeterReading />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/edit/:id/:op',
    permission: '',
    element: <EditShiftOP />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/create',
    permission: PermissionsType.P_WORKSHITFCALENDARCREATE,
    element: <ShiftOP />,
  },
  {
    path: '/energy/teamShift/shiftCalendar/detail/:id/:op',
    permission: '',
    element: <ShiftView />,
  },
  {
    path: '/productionManagement/production/WorkCenterTopoPage',
    permission: PermissionsType.P_WORKCENTERSTRUCTUREDIAGRAM,
    element: <WorkCenterTopoPage />,
  },
  {
    path: '/systemConfig/productionTopo/materielManagement',
    permission: PermissionsType.P_MATERIAL_MANAGE,
    element: <MaterielManagement />,
  },
  {
    path: '/energy/analysis/unitSankey',
    permission: PermissionsType.P_UNIT_ENERGY_FLOW_ANALYSIS,
    element: <PlustekSankey />,
  },
  {
    path: '/systemConfig/productionTopo/energyManage',
    permission: PermissionsType.P_ENERGYMANAGEMENT,
    element: <EnergyManagement />,
  },
  {
    path: '/systemConfig/productionTopo/baseLineManage',
    permission: PermissionsType.P_ENERGY_CONSUMPTIONBASELINEMANAGE,
    element: <BaseLineManage />,
  },
  {
    path: '/systemConfig/productionTopo/baseLineManage/create',
    permission: PermissionsType.P_ADDUNITCONSUMPTIONBASELINE,
    element: <CreateBaseLine />,
  },
  {
    path: '/systemConfig/productionTopo/baseLineManage/edit/:id',
    permission: PermissionsType.P_EDITUNITCONSUMPTIONBASELINE,
    element: <CreateBaseLine isEdit />,
  },
  {
    path: '/systemConfig/productionTopo/baseLineManage/detail/:id',
    permission: '',
    element: <ViewBaseLine />,
  },
  {
    path: '/productCarbonFootPrint/basic/modelManage',
    permission: PermissionsType.P_PCFMODELMANAGEMENT,
    element: <ModelManage />,
  },
  {
    path: '/productCarbonFootPrint/basic/modelManage/createModel',
    permission: '',
    element: <CarbonCreateModel />,
  },
  {
    path: '/productCarbonFootPrint/basic/modelManage/newModel',
    permission: PermissionsType.P_MAINTENANCEPCFMODEL,
    element: <CarbonNewModel />,
  },
  {
    path: '/productCarbonFootPrint/basic/modelManage/normalInput',
    permission: '',
    element: <NormalInput />,
  },
  {
    path: '/productCarbonFootPrint/basic/modelManage/editInput',
    permission: '',
    element: <EditInput />,
  },
  {
    path: '/productCarbonFootPrint/basic/modelManage/addOutput',
    permission: '',
    element: <AddOutput />,
  },
  {
    path: '/temporary/basic/screen',
    element: <Sceen />,
  },
  {
    path: '/temporary/basic/energyBill',
    element: <EnergyBill />,
  },
  {
    path: '/temporary/basic/deviceStatus',
    element: <DeviceStatus />,
  },
  {
    path: '/temporary/basic/energyCost',
    element: <EnergyCost />,
  },
  {
    path: '/temporary/basic/energyCost',
    element: <EnergyCost />,
  },
  {
    path: '/temporary/basic/energyTopo',
    element: <EnergyTopo />,
  },
  {
    path: '/temporary/basic/powerLoss',
    element: <PowerLoss />,
  },
  {
    path: '/temporary/basic/gasLoss',
    element: <GasLoss />,
  },
  {
    path: '/productCarbonFootPrint/basic/reporting',
    permission: PermissionsType.P_PCFFILLING,
    element: <CarbonFootprintReporting />,
  },
  {
    path: '/productCarbonFootPrint/basic/reporting/addFilling',
    permission: '',
    element: <CarbonFootprintAddFiling />,
  },
  {
    path: '/productCarbonFootPrint/basic/reporting/detail',
    permission: '',
    element: <CarbonFootprintDetailFiling />,
  },
  {
    path: '/productCarbonFootPrint/allocation/materialWarehousingManagement',
    permission: PermissionsType.P_MATERIAL_RECEIPT_MANAGEMENT,
    element: <MaterialWarehousingManagement />,
  },
  {
    path: '/productCarbonFootPrint/allocation/materialWarehousingManagement/detail',
    permission: PermissionsType.P_MATERIAL_RECEIPT_MANAGEMENT,
    element: <DetailMaterialWarehousingManagement />,
  },
  {
    path: '/carbonManage/mainAccount/carbonAccountUnits/create',
    permission: PermissionsType.P_CARBON_ACCOUNTING_ENTITY_DETAIL,
    element: <AddAccountingEntity />,
  },
  {
    path: '/energy/analysis/plustekEnergyPlan',
    permission: PermissionsType.P_ENERGY_USE_PLAN,
    element: <PlustekEnergyPlan />,
  },
  {
    path: '/energy/analysis/plustekEnergyPlan/edit/:id',
    permission: PermissionsType.P_EDIT_ENERGY_USE_PLAN,
    element: <EditPlan isEdit />,
  },
  {
    path: '/energy/analysis/plustekEnergyPlan/detail/:id',
    permission: PermissionsType.P_ENERGY_USE_PLAN_VIEW,
    element: <EditPlan />,
  },
  {
    path: '/productCarbonFootPrint/config/supplierManage',
    permission: PermissionsType.P_SUPPLIERMANAGEMENT,
    element: <SupplierManage />,
  },
  {
    path: '/energy/consumption/analysis/energyConsumingUnits',
    // permission: PermissionsType.P_SUPPLIERMANAGEMENT,
    element: <EnergyConsumingUnits />,
  },
  {
    path: '/productionManagement/basic/manage',
    permission: PermissionsType.P_PRODUCTIONPROCESSMANAGEMENT,
    element: <ProductiveProcessManage />,
  },
  {
    path: '/energy/analysis/productionBeatDemo',
    permission: PermissionsType.P_TAKTTIME,
    element: <ProductionBeatDemo />,
  },
  {
    path: '/energy/analysis/productionBeatView',
    permission: PermissionsType.P_TAKTTIMEDIAGNOSIS,
    element: <ProductionBeatView />,
  },
  {
    path: '/energy/appAnalyticsSubscription/productionProcess/:id',
    // permission: PermissionsType.P_TAKTTIMECONFIG,
    element: <ProductionBeatConfig />,
  },
  {
    path: '/energy/appAnalyticsSubscription/productionProcess',
    permission: PermissionsType.P_APPANALYTICSSUBSCRIPTION,
    element: <ProductionProcess />,
  },
  {
    path: '/energy/appAnalyticsSubscription/productionProcess/batch',
    permission: '',
    element: <BatchOperationApp />,
  },
  {
    path: '/productCarbonFootprint/carbonFootprintAnalysis/overview',
    permission: PermissionsType.P_CFPOVERVIEW,
    element: <CarbonFootprintOverview />,
  },
  {
    path: '/energy/appAnalyticsSubscription/productionProcess/intervalConfig/:id',
    permission: PermissionsType.P_ENERGYCONSUMPTIONTHRESHOLDCONFIGURATION,
    element: <EnergyIntervalConfig />,
  },
  {
    path: '/energy/appAnalyticsSubscription/productionProcess/oeeConfig/:id',
    permission: PermissionsType.P_ENERGYCONSUMPTIONTHRESHOLDCONFIGURATION,
    element: <EnergyOEEConfig />,
  },
  {
    path: '/production/manage/gasMachineDashBoard',
    permission: PermissionsType.P_GASSTATIONBOARD,
    element: <GasMachineDashBoard />,
  },
  {
    path: '/production/manage/gasMachineDashBoard/dashBoardConfig/:id/:type',
    permission: PermissionsType.P_BOARDCONFIGPAGE,
    element: <DashBoardConfig />,
  },
  {
    path: '/energy/basic/uet/edit/:id/:key/intrasiteTopology',
    permission: '',
    element: <IntrasiteTopology />,
  },
  // 空压站制氮机详情
  {
    path: '/production/manage/gasMachineDashBoard/compressionStationDetail/:id',
    permission: PermissionsType.P_STATIONDETAILS,
    element: <MonitorDeviceDashBoard />,
  },
  // 单设备详情
  {
    path: '/production/manage/gasMachineDashBoard/SingleDeviceBoardDetail/:id',
    permission: PermissionsType.P_NODEDETAILS,
    element: <SingleDeviceBoard />,
  },
  {
    path: '/energy/basic/uet/edit/:id/:key/hvacTopology',
    permission: '',
    element: <HVACTopology />,
  },
  {
    path: '/TicketCenter/WorkOrderManagement/list',
    permission: PermissionsType.P_DISPATCHPROGRAMLIST,
    element: <WorkOrderManagement />,
  },
  {
    path: '/TicketCenter/WorkOrderManagement/list/create',
    permission: '',
    element: <WorkOrderManagementCreate />,
  },
  {
    path: '/TicketCenter/WorkOrderManagement/list/detail/:id',
    permission: '',
    element: <WorkOrderManagementDetail />,
  },
  {
    path: '/TicketCenter/WorkOrderManagement/list/edit/:id',
    permission: '',
    element: <WorkOrderManagementCreate isEdit />,
  },
  {
    path: '/energy/analysis/equipmentProductPlan',
    permission: PermissionsType.P_DEVICESCHEDULEDPRODUCTION,
    element: <EquipmentProductPlan />,
  },
  {
    path: '/energy/analysis/equipmentProductPlan/add',
    permission: PermissionsType.P_DEVICESCHEDULEDPRODUCTION,
    element: <AddProductPlan />,
  },
  {
    path: '/energy/analysis/equipmentProductPlan/edit/:id',
    permission: PermissionsType.P_DEVICESCHEDULEDPRODUCTION,
    element: <AddProductPlan isEdit />,
  },
  {
    path: '/energy/output/category',
    permission: PermissionsType.P_DESCRIPTIONANDSPECIFICATION,
    element: <OutputCategory />,
  },
  {
    path: '/energy/oee/application',
    permission: PermissionsType.P_OEEAPPLICATIONPAGE,
    element: <OEEApplication />,
  },
  {
    path: '/productCarbonFootPrint/config/materialFeedingManagement',
    permission: PermissionsType.P_MATERIALFEEDINGMANAGEMENT,
    element: <MaterialFeedingManagement />,
  },
  {
    path: '/productCarbonFootPrint/config/productionReportingManagement',
    permission: PermissionsType.P_PRODUCTIONREPORTINGMANAGEMENT,
    element: <ProductionReportingManagement />,
  },
  {
    path: '/energy/workshop/overview/config',
    // permission: PermissionsType.P_KANBANPRODUCTIONFLOORCONGIF,
    element: <WorkShopOverviewConfig />,
  },
  {
    path: '/systemConfig/eneryTopo/unitConfig',
    permission: PermissionsType.P_DISPLAYUNITCONFIG,
    element: <MetricUnitConfiguration />,
  },
  {
    path: '/production/manage/hvacBoard',
    permission: PermissionsType.P_KANBANHAVC,
    element: <HVACBoard />,
  },
  {
    path: '/production/manage/hvacBoard/boardConfig/:id',
    permission: PermissionsType.P_HVACMONITORINDEXCONFIG,
    element: <HVACDashBoardConfig />,
  },
  {
    path: '/production/manage/hvacBoard/stationDetail/:id',
    permission: PermissionsType.P_HVACSTATIONDETAILS,
    element: <HVACDashBoard />,
  },
  {
    path: '/production/manage/hvacBoard/SingleDeviceBoardDetail/:id',
    permission: PermissionsType.P_CHILLERDETAILS,
    element: <HVACDeviceDashBoard />,
  },
  {
    path: '/asset/equipment/ledger',
    permission: PermissionsType.P_EQUIPMENTLEDGER,
    element: <EquipmentLedger />,
  },
  {
    path: '/asset/equipment/ledger/create',
    permission: PermissionsType.P_EQUIPMENTLEDGER,
    element: <EquipmentLedgerCreate />,
  },
  {
    path: '/asset/equipment/ledger/edit/:id',
    permission: PermissionsType.P_EQUIPMENTLEDGER,
    element: <EquipmentLedgerCreate isEdit />,
  },
  {
    path: '/asset/equipment/ledger/detail/:id',
    permission: PermissionsType.P_EQUIPMENTLEDGER,
    element: <EquipmentLedgerDetail />,
  },
  {
    path: '/energy/consumption/inout',
    // permission: PermissionsType.P_DEVICESCHEDULEDPRODUCTION,
    element: <InputOutputInfo />,
  },
  {
    path: '/productionUnit/consumption/board',
    // permission: PermissionsType.P_PRODUCTION_UNIT_CONSUMPTION_KANBAN,
    element: <ProductionUnitConsumptionBoard />,
  },
];

export default mainRoutes;
