import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Cascader, Space, AutoComplete } from 'antd';
import {
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  Modal,
  Input,
  Select,
  DatePicker,
  TreeSelect,
  Radio,
  Form,
  FormTitle,
  SubContent,
  Upload,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';
import {
  getOrganizationWithCodeWithCurrent,
  OrganizationResponse,
  deviceAssetTypeItem,
  deviceAssetTypeEmun,
  PhysicalModelRes,
  getPhysicalModelList,
} from '../../../api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { useQuery } from '../../../utils/utils';
import { getDeviceTypeTree } from '../../../api/deviceType';
import {
  getDeviceProperty,
  createDeviceProperty,
  updateDeviceProperty,
  DevicePropertyResponse,
} from '../../../api/device';
import dayjs, { Dayjs } from 'dayjs';
import { getModel, ModelResponse } from '../../../api/model';
import MultiModalSelect from '@/shared/components/MultiModalSelect';
import { checkOuIsBound } from '../../../api/implement';
import { getDefaultKey } from '@/shared/api/fileCenter';

import DeviceTags, { deviceTagsValidator } from '@/shared/components/DeviceTags';
import { formatDeviceTypeToDataNode } from '@/shared/utils/utils';
import { isNil } from 'lodash-es';

export const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

type RecordMap = Map<'old' | 'new', Array<number>>;

const CreateDevicePropertyInfo: FC<{ isEdit?: boolean; isNext?: boolean }> = ({ isEdit = false, isNext = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [form] = Form.useForm();
  const [selectedMcid, setSelectedMcid] = useState<string>();
  const [ouRecords, setOuRecords] = useState<RecordMap>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [showEnergyAssetInfoVisible, setShowEnergyAssetInfoVisible] = useState<boolean>(false);
  const [deviceAsset, setDeviceAsset] = useState<deviceAssetTypeItem>();

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表

  const showEnergyAssetInfo = (val: deviceAssetTypeItem) => {
    setShowEnergyAssetInfoVisible(true);
  };

  const isShowTag = useMemo(() => {
    return (
      deviceAsset?.[deviceAssetTypeEmun.PVSTATION]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.ENERGYSTORAGEARRAY]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.NEW_ENERGYSTORAGEARRAY]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.NETENERGY]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.CHARGING]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.TOPOLOGY]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.GAS_PREPARATION_STATION]?.length ||
      deviceAsset?.[deviceAssetTypeEmun.HVAC]?.length
    );
  }, [deviceAsset]);

  // 光伏站编号
  const pvstation = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.PVSTATION]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  // 储能编号
  const energyStorageArray = useMemo(() => {
    return [
      ...(deviceAsset?.[deviceAssetTypeEmun.ENERGYSTORAGEARRAY] ?? []),
      ...(deviceAsset?.[deviceAssetTypeEmun.NEW_ENERGYSTORAGEARRAY] ?? []),
    ]
      .map(m => m.deviceAssetCode)
      .join();
  }, [deviceAsset]);

  // 微网能源编号
  const netEnergyArray = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.NETENERGY]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);
  // 充电站能源编号
  const changeEnergyArray = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.CHARGING]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  // 气体制备站编号
  const gPsEnergyArray = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.GAS_PREPARATION_STATION]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  // 暖通空调
  const hvacEnergyArray = useMemo(() => {
    return deviceAsset?.[deviceAssetTypeEmun.HVAC]?.map(m => m.deviceAssetCode).join();
  }, [deviceAsset]);

  const defaultImgKey = useAsync(getDefaultKey, {});

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}设备` }];
  }, [isEdit]);

  const [deviceProperty, setDeviceProperty] = useState<DevicePropertyResponse>();

  useEffect(() => {
    if (id) {
      getDeviceProperty(id).then(res => {
        setDeviceProperty(res);
        setDeviceAsset(res.deviceAsset);
        const record: RecordMap = new Map();
        record.set('old', res.ouIds || []);
        record.set('new', []);
        setOuRecords(record);
      });
    }
  }, [id]);

  const { data: deviceTypes } = useQuery(useCallback(() => getDeviceTypeTree(), []));
  const [deviceType, setDeviceType] = useState<number>();

  useEffect(() => {
    if (deviceProperty) {
      form.setFieldsValue({
        name: deviceProperty.name,
        sn: deviceProperty.sn,
        typeId: deviceProperty.typeName,
        modelName: deviceProperty.modelName,
        manufactureDate: deviceProperty.manufactureDate ? dayjs(deviceProperty.manufactureDate) : undefined,
        installationDate: deviceProperty.installationDate ? dayjs(deviceProperty.installationDate) : undefined,
        pic: deviceProperty.pic,
        // rootMcid: deviceProperty.tenantName,
        customerMcid: deviceProperty.customerMcid,
        assetCode: deviceProperty.assetCode,
        ouIds: deviceProperty.ouIds,
        tags: deviceProperty.tags,
        objectModalType: deviceProperty.physicalModelId
          ? `${deviceProperty.physicalModelNo ?? deviceProperty.modelNo}-${deviceProperty.manufacturerName}`
          : deviceProperty.physicalModelId,
        thirdParty: deviceProperty.thirdParty,
      });
      setDeviceType(deviceProperty.typeId);
      setSelectedMcid(deviceProperty.customerMcid);
    }
  }, [deviceProperty, form]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);

  useEffect(() => {
    // 物模型型号
    if (deviceType) {
      getPhysicalModelList({ deviceTypeIds: [deviceType] }).then(res => setPhysicalModelList(res.list));
    }
  }, [deviceType]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
  }, [physicalModelList]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const onFinish = async (type: boolean) => {
    try {
      const v = await form.validateFields();
      let promise: () => Promise<DevicePropertyResponse>;
      if (isEdit) {
        // 编辑
        promise = () =>
          updateDeviceProperty({
            id: Number(id),
            name: v.name,
            sn: v.sn,
            manufactureDate: v.manufactureDate ? dayjs(v.manufactureDate).format('YYYY-MM-DD') : undefined,
            installationDate: v.installationDate ? dayjs(v.installationDate).format('YYYY-MM-DD') : undefined,
            pic: v.pic ?? defaultImgKey['defaultDeviceKey'],
            customerMcid: v.customerMcid,
            assetCode: v.assetCode,
            addOuIds: v.ouIds?.filter((id: number) => !deviceProperty?.ouIds?.includes(id)),
            deleteOuIds: deviceProperty?.ouIds?.filter((id: number) => !v.ouIds?.includes(id)),
            tags: v.tags,
            physicalModelId: deviceProperty?.physicalModelId ? deviceProperty?.physicalModelId : v.objectModalType,
            thirdParty: v.thirdParty,
          });
      } else {
        // 添加
        promise = () =>
          createDeviceProperty({
            name: v.name,
            sn: v.sn,
            typeId: deviceType,
            modelName: v.modelName,
            manufactureDate: v.manufactureDate ? dayjs(v.manufactureDate).format('YYYY-MM-DD') : undefined,
            installationDate: v.installationDate ? dayjs(v.installationDate).format('YYYY-MM-DD') : undefined,
            pic: v.pic ?? defaultImgKey['defaultDeviceKey'],
            // rootMcid: v.rootMcid,
            customerMcid: v.customerMcid,
            assetCode: v.assetCode,
            addOuIds: v.ouIds,
            tags: v.tags,
            physicalModelId: v.objectModalType,
            thirdParty: v.thirdParty,
          });
      }
      const submit = () =>
        promise().then(res => {
          if (type) {
            navigate(`/device/manage/device/${res.id}/attribute/create`);
          } else {
            navigate(`/device/manage/device`);
          }
        });
      let needWarn = v.ouIds?.length
        ? await checkOuIsBound({
            addOuIds: ouRecords?.get('new') || v.ouIds,
            oldOuIds: ouRecords?.get('old'),
          })
        : false;
      if (needWarn) {
        Modal.confirm({
          title: '设备存在多个运营单元',
          content: (
            <>
              <span style={{ color: 'red' }}>可能会有数据重复</span>，你还要继续吗？
            </>
          ),
          okText: '继续',
          onOk: submit,
        });
      } else {
        await submit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const [model, setModel] = useState<ModelResponse[]>([]);

  useEffect(() => {
    if (deviceType !== undefined) {
      getModel(deviceType).then(setModel);
    }
  }, [deviceType]);

  const [searchValue, setSearchValue] = useState<string>();

  const options = useMemo(() => {
    return model.filter(i => i.name.includes(searchValue ?? '')).map(i => ({ label: i.name, value: i.name }));
  }, [model, searchValue]);

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const onBlur = () => {
    setSearchValue(undefined);
  };

  const onCacel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      okText: '确定',
      onOk: () => {
        navigate(`/device/manage/device`);
      },
    });
  };

  const onRecord = (v: Array<number> | undefined) => {
    if (isEdit) {
      const defaultOldOuIds: Array<number> = ouRecords!.get('old')!;
      const newOuIds = v?.filter((id: number) => !defaultOldOuIds?.includes(id)) || [];
      const oldOuIds = defaultOldOuIds.filter((id: number) => v?.includes(id)) || [];
      const record: RecordMap = new Map();
      record.set('old', oldOuIds);
      record.set('new', newOuIds);
      setOuRecords(record);
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={`${isEdit ? '编辑' : '新建'}设备`} />
      <SubContent>
        <Form form={form} layout="vertical" {...formLayout}>
          <Row>
            <Col span={8}>
              <Form.Item
                name="name"
                label="设备名称"
                rules={[
                  { required: true, message: '请输入设备名称' },
                  { max: 50, message: '不超过50位' },
                ]}
              >
                <Input placeholder="请输入设备名称，不超过五十个字" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="sn"
                label="设备S/N码"
                rules={[
                  { required: true, message: '请输入设备S/N码' },
                  { pattern: /^([0-9a-zA-Z-]|\/)+$/g, message: '请输入英文、数字、特殊符号包括【/】、【-】' },
                  { max: 50, message: '设备S/N码不超过50个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="typeId" label="所属类目" rules={[{ required: true, message: '请选择所属类目' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <Cascader
                    allowClear={false}
                    style={{ width: '100%' }}
                    options={formatDeviceTypeToDataNode(deviceTypes?.tree ?? [])}
                    fieldNames={{ label: 'name', value: 'id' }}
                    onChange={(e: any) => {
                      if (e.length < 3) {
                        form.setFieldsValue({ typeId: undefined });
                      }
                      setDeviceType(e[2] as number | undefined);
                      form.setFieldsValue({
                        modelName: undefined,
                        objectModalType: undefined,
                      });
                    }}
                    placeholder="请选择"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="objectModalType" label="物模型型号">
                {isEdit ? (
                  isNil(form.getFieldValue('objectModalType')) ? (
                    <Select
                      allowClear
                      optionFilterProp="label"
                      showSearch
                      placeholder={form.getFieldValue('typeId') ? '请选择' : '请先选择所属类目'}
                      options={objectModalTypeOptions}
                      disabled={!form.getFieldValue('typeId')}
                    />
                  ) : (
                    <ShowInput />
                  )
                ) : (
                  <Select
                    allowClear
                    options={objectModalTypeOptions}
                    optionFilterProp="label"
                    showSearch
                    disabled={!form.getFieldValue('typeId')}
                    placeholder={form.getFieldValue('typeId') ? '请选择' : '请先选择所属类目'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="modelName" label="所属型号" rules={[{ required: true, message: '请选择所属型号' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <AutoComplete
                    disabled={deviceType === undefined}
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="请输入"
                    onSearch={onSearch}
                    onBlur={onBlur}
                    optionFilterProp="label"
                    options={options}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="manufactureDate" label="出厂日期">
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={(currentDate: Dayjs) => {
                    return currentDate.isAfter(dayjs(), 'date') || currentDate.isBefore(dayjs('1950-01-01'));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="installationDate" label="安装日期">
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={(currentDate: Dayjs) => {
                    return currentDate.isAfter(dayjs(), 'date') || currentDate.isBefore(dayjs('1950-01-01'));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="customerMcid" label="所属组织" rules={[{ required: true, message: '请选择所属组织' }]}>
                <TreeSelect
                  onChange={val => {
                    setSelectedMcid(val as string);
                    form.setFieldsValue({ ouIds: [] });
                  }}
                  treeData={treeData}
                  placeholder="请选择"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="assetCode" label="资产编码" rules={[{ max: 500, message: '最多输入500位' }]}>
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="pic" label="设备图片">
                <Upload tip="支持.jpg,.jpeg,.png格式，文件小于5MB" fileSize={5} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouIds" label="运营单元">
                <MultiModalSelect onChange={onRecord} mcid={selectedMcid} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="tags" label="设备标签" rules={[{ validator: deviceTagsValidator }]}>
                <DeviceTags />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="能源资产信息">
                {isShowTag ? (
                  <Button type="link" onClick={() => showEnergyAssetInfo(deviceAsset!)}>
                    点击查看
                  </Button>
                ) : (
                  '暂无'
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              {/* 否的情况下 配置了数采。不能改为是 */}
              <Form.Item name="thirdParty" label="第三方对接" rules={[{ required: true }]}>
                <Radio.Group disabled={!deviceProperty?.thirdParty && deviceProperty?.existEdgeTemplate}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </SubContent>

      <Space size={8} className="sticky-footer">
        {isNext && (
          <Button
            type="primary"
            onClick={() => {
              onFinish(true);
            }}
          >
            保存并添加固定属性模板
          </Button>
        )}
        <Button
          type="primary"
          onClick={() => {
            onFinish(false);
          }}
        >
          保存
        </Button>
        <Button className={styles.button} onClick={onCacel}>
          取消
        </Button>
      </Space>
      <Modal
        title="能源资产信息"
        open={showEnergyAssetInfoVisible}
        onCancel={() => setShowEnergyAssetInfoVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setShowEnergyAssetInfoVisible(false)}>
            关闭
          </Button>,
        ]}
      >
        {pvstation && (
          <>
            <h3 style={{ fontWeight: 700 }}>光伏能源资产编号</h3>
            <p>{pvstation}</p>
          </>
        )}
        {energyStorageArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>储能能源资产编号</h3>
            <p> {energyStorageArray}</p>
          </>
        )}
        {netEnergyArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>微网能源资产编号</h3>
            <p> {netEnergyArray}</p>
          </>
        )}
        {changeEnergyArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>充电站能源资产编号</h3>
            <p> {changeEnergyArray}</p>
          </>
        )}
        {gPsEnergyArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>气体制备站能源资产编号</h3>
            <p> {gPsEnergyArray}</p>
          </>
        )}
        {hvacEnergyArray && (
          <>
            <h3 style={{ fontWeight: 700 }}>暖通空调能源资产编号</h3>
            <p> {hvacEnergyArray}</p>
          </>
        )}
      </Modal>
    </Wrapper>
  );
};

export default CreateDevicePropertyInfo;
