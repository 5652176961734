import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { PageRequest, PageResponse } from './page';

export interface ImplementPageRequest extends PageRequest {
  nameOrSerialNum?: string;
}

export interface Implement {
  id: number;
  serialNumber: string;
  name: string;
  ouTypeName: string;
  tenantName: string;
  adminNum: number;
  memberNum: number;
  updateByUsername: string;
  updateTime: string;
}

export type ImplementPageResponse = PageResponse<Implement>;

export const getImplementPage = (params: ImplementPageRequest): Promise<ImplementPageResponse> =>
  fetch<ImplementPageResponse>(
    `/api/v2/ou/managePage`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface ImplementAdmin {
  humanCode: string;
  id: number;
  name: string;
  orgName: string;
  phone: number;
  staffCode: string;
  staffType: number;
  username: string;
}

export type ImplementAdminListResponse = Array<ImplementAdmin>;

export const getImplementAdminList = (id: number): Promise<{ list: ImplementAdminListResponse }> =>
  fetch(
    `/api/v2/ou/loadManageStaffInfoList`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

interface ImplementDetail extends Implement {
  directorName: string;
}

export const getImplementDetail = (id: number) =>
  fetch<ImplementDetail>(
    `/api/v2/ou/manage/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface AdminStaffPageRequest extends PageRequest {
  name?: string;
  username?: string;
  staffCode?: string;
  phone?: string;
  humanCode?: string;
}

export interface AdminStaff {
  humanCode: string;
  id: number;
  name: string;
  orgName: string;
  phone: number;
  staffCode: string;
  staffType: number;
  username: string;
}

export type AdminStaffPageResponse = PageResponse<AdminStaff>;

export const getAdminStaffPage = (params: AdminStaffPageRequest): Promise<AdminStaffPageResponse> =>
  fetch(
    `/api/v2/ou/user/managePage`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface UnbindOuRequest {
  ouId: number;
  staffId: number;
}

export const unbindOu = (params: UnbindOuRequest): Promise<void> =>
  fetch(`/api/ou/${params.ouId}/unbind?staffId=${params.staffId}`, { method: 'PUT' });

export interface SaveOuAdminRequest {
  ouId: number;
  memberIds?: Array<number>;
  adminIds?: Array<number>;
}

export const saveOuAdmin = (params: SaveOuAdminRequest) =>
  fetch(
    `/api/v2/ou/manage`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface CheckOuIdsBoundRequest {
  addOuIds: Array<number> | undefined;
  oldOuIds: Array<number> | undefined;
}

export interface CheckOuIsBoundResponse {
  deviceId: Array<number>;
}

export const checkOuIsBound = (params: CheckOuIdsBoundRequest): Promise<CheckOuIsBoundResponse> =>
  fetch(
    `/api/v2/ou/checkOuDevice`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
