import dayjs, { Dayjs } from 'dayjs';

// 时间颗粒度, 按小时, 天, 月
export enum TimeGranularity {
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
}

export const TimeGranularityDisplay = {
  [TimeGranularity.HOUR]: '按小时',
  [TimeGranularity.DAY]: '按日',
  [TimeGranularity.MONTH]: '按月',
};

type RangeValue = [Dayjs | null, Dayjs | null] | null;

// 不同时间段下的初始值
export const TimeGranularityInitialValue: Record<string, RangeValue> = {
  [TimeGranularity.DAY]: [dayjs().subtract(30, 'day'), dayjs()],
  [TimeGranularity.MONTH]: [dayjs().startOf('month').subtract(11, 'month'), dayjs().endOf('month')],
  [TimeGranularity.HOUR]: [dayjs().startOf('day'), dayjs()],
};
