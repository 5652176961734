import React, { useEffect, useMemo, useState } from 'react';
import { Empty } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { Input, Tree, getRealUrl } from '@maxtropy/components';
import { apiV2ProductionBaseCompleteTreePost } from '@maxtropy/device-customer-apis-v2';
import productionBaseIcon from '../../images/productionBase.png';
import root from '../../images/workCenter.png';
import workProcedure from '../../images/productionProcesse.png';
import workStation from '../../images/workStation.png';
import energyUnit from '../../images/energyConsumingUnit.png';
import styles from './index.module.scss';

type TreeDataNode = DataNode & { originalName?: string; img?: React.ReactNode };

export const getFlattenTreeData = (
  data: TreeDataNode[]
): { title: any; originalName?: string; key: React.Key; children: any }[] => {
  return data.flatMap(({ title, originalName, key, children }) => [
    { title, originalName, key, children },
    ...getFlattenTreeData(children || []),
  ]);
};

const getParentKey = (key: React.Key, tree: TreeDataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

const StationTree = (props: any) => {
  const { setSelectedKeys, selectedKeys, setSelectedBasePhysicalQuantityId } = props;

  const [searchValue, setSearchValue] = useState('');
  // 左侧树
  const [tree, setTree] = useState<TreeDataNode[]>([]);
  const [completeTree, setCompleteTree] = useState<any>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  // 记录选中的名称
  const [selectedName, setSelectedName] = useState<string | undefined>(undefined);

  useEffect(() => {
    apiV2ProductionBaseCompleteTreePost({}).then(res => {
      setCompleteTree(res.list ?? []);
      const formattedTree: TreeDataNode[] = (res.list ?? []).map(pd => {
        return {
          title: pd.name,
          disabled: true,
          icon: (
            <img
              src={getRealUrl(pd?.iconKey) ?? productionBaseIcon}
              width={16}
              height={16}
              alt=""
              style={{ marginBottom: -6 }}
            />
          ),
          // 记录名称, 以 工作中心-工序-工站-用能单元为样式
          display: pd.name,
          key: `productionBase-${pd.id}`,
          children: pd?.workCenterTree?.map(wc => ({
            title: wc.name,
            icon: (
              <img src={getRealUrl(wc?.iconKey) ?? root} width={16} height={16} alt="" style={{ marginBottom: -6 }} />
            ),
            // 记录名称, 以 工作中心-工序-工站-用能单元为样式
            display: wc.name,
            key: `workCenter-${wc.id}`,
            children: wc?.procedureTree?.map(wp => ({
              title: wp.procedureName,
              icon: (
                <img
                  src={getRealUrl(wp?.iconKey) ?? workProcedure}
                  width={16}
                  height={16}
                  alt=""
                  style={{ marginBottom: -6 }}
                />
              ),
              display: `${wc.name}-${wp.procedureName}`,
              key: `workProcedure-${wp.procedureId}-${wp.centerProcedureLinkId}-${wp.workCenterId}`,
              children: wp?.stationTree?.map(ws => ({
                title: ws.stationName,
                icon: (
                  <img
                    src={getRealUrl(ws?.iconKey) ?? workStation}
                    width={16}
                    height={16}
                    alt=""
                    style={{ marginBottom: -6 }}
                  />
                ),
                display: `${wc.name}-${wp.procedureName}-${ws.stationName}`,
                key: `workStation-${ws.stationId}-${ws.procedureStationLinkId}-${ws.centerProcedureLinkId}-${ws.workCenterId}`,
                children: ws?.unitTree?.map(ut => ({
                  title: ut.energyUnitName,
                  icon: <img src={energyUnit} width={16} height={16} alt="" style={{ marginBottom: -6 }} />,
                  display: `${wc.name}-${wp.procedureName}-${ws.stationName}-${ut.energyUnitName}`,
                  key: `energyUnit-${ut.energyUnitId}-${ut.procedureStationLinkId}-${ut.centerProcedureLinkId}-${ut.workCenterId}`,
                })),
              })),
            })),
          })),
        };
      });
      console.log(formattedTree, 'formattedTree');
      setTree(formattedTree);
    });
  }, []);

  useEffect(() => {
    // 默认选中第一个工作中心
    if (tree.length > 0 && tree[0].children && tree[0].children.length > 0) {
      setSelectedName(tree?.[0]?.children?.[0]?.title as string);
      setSelectedKeys([tree?.[0]?.children?.[0]?.key as string]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree]);

  const flattenedTree = useMemo(() => {
    return getFlattenTreeData(tree);
  }, [tree]);

  useEffect(() => {
    // 左侧树默认全部展开
    if (flattenedTree.length) {
      setExpandedKeys(flattenedTree.map(data => data.key));
    }
  }, [flattenedTree]);

  // 完整左侧树, 包括 icon, 高亮
  const treeData = useMemo(() => {
    const loop = (data: TreeDataNode[]): TreeDataNode[] =>
      data.map(item => {
        const strTitle = item.title as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);

        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className={styles.treeSearchValue}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return {
            title,
            icon: item.icon,
            key: item.key,
            // @ts-ignore
            display: item.display,
            children: loop(item.children),
          };
        }

        return {
          icon: item.icon,
          title,
          // @ts-ignore
          display: item.display,
          key: item.key,
        };
      });

    return loop(tree);
  }, [searchValue, tree]);

  return (
    <div className={styles.treeArea}>
      <div className={styles.searchArea}>
        <Input.Search
          placeholder="请输入"
          allowClear
          onSearch={value => {
            const newExpandedKeys = flattenedTree
              .map(item => {
                if (item.title.indexOf(value)! > -1) {
                  return getParentKey(item.key, treeData);
                }
                return null;
              })
              .filter((item, i, self) => item && self.indexOf(item) === i);
            setExpandedKeys(newExpandedKeys as React.Key[]);
            setAutoExpandParent(true);
            setSearchValue(value);
          }}
        />
      </div>
      <div className={styles.treeBox}>
        {tree.length ? (
          <Tree
            showIcon
            blockNode
            onExpand={newExpandedKeys => {
              setExpandedKeys(newExpandedKeys);
              setAutoExpandParent(false);
            }}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            style={{ height: 'calc(100vh - 180px)' }}
            treeData={treeData}
            // defaultExpandAll
            selectedKeys={selectedKeys}
            onSelect={(selectedKeys, info) => {
              console.log(selectedKeys, info);

              if (typeof selectedKeys[0] === 'string') {
                if (selectedKeys[0].split('-')[0] === 'productionBase') {
                  return;
                }
              }
              setSelectedKeys(selectedKeys);
              setSelectedBasePhysicalQuantityId(undefined);

              if (info.selected) {
                // @ts-ignore
                setSelectedName(info.node.display);
              } else {
                setSelectedName(undefined);
              }
            }}
          />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

export default StationTree;
