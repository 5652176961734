import React, { useCallback, useEffect, useState } from 'react';
import { Space } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Select } from '@maxtropy/components';
import { apiV2ProductionConsumptionOutputListPost } from '@maxtropy/device-customer-apis-v2';
import { useQuery } from '@/utils/utils';
import styles from './index.module.scss';

const OutPutBox = () => {
  const [isExpand, setExpand] = useState<boolean>(true);
  const [selectedValue, setSelectedValue] = useState<number>();

  const { data, isLoading } = useQuery(
    useCallback(() => apiV2ProductionConsumptionOutputListPost({}, { server: 'mock' }).then(res => res.list ?? []), [])
  );

  useEffect(() => {
    const activeEle = document.getElementById('active');
    if (activeEle) {
      const tagBox = document.getElementById('tagBox')?.getBoundingClientRect();
      const activeBoxInfo = document.getElementById('active')?.getBoundingClientRect();
      if (activeBoxInfo?.bottom! > tagBox?.bottom!) {
        document.getElementById('tagBox')!.scrollTop = activeBoxInfo?.bottom! - tagBox?.height!;
      }
    }
  }, [selectedValue]);

  return (
    <div>
      <Space size={8}>
        <Select
          style={{ width: 268 }}
          value={selectedValue}
          options={(data ?? []).map(i => ({ label: i.code, value: i.id }))}
          onChange={value => setSelectedValue(value)}
        />
        <div onClick={() => setExpand(!isExpand)}>
          {isExpand ? (
            <Button type="link">
              <DownOutlined />
              收起
            </Button>
          ) : (
            <Button type="link">
              <UpOutlined />
              展开
            </Button>
          )}
        </div>
      </Space>
      {data && data.length > 0 && (
        <div className={styles.tags} id="tagBox">
          {data.map(item => (
            <div
              key={item.id}
              id={selectedValue === item.id ? 'active' : 'default'}
              className={`${styles.tagItem} ${selectedValue === item.id ? styles.active : ''}`}
              onClick={() => setSelectedValue(item.id)}
            >
              {item.code}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OutPutBox;
