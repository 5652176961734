import { FC, useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  Table,
  EllipsisSpan,
  useUpdate,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  DatePicker,
  Form,
  Tag,
  Modal,
  Input,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';

import {
  AlarmLevel,
  AlarmLevelColorDisplay,
  AlarmLevelDisplay,
  AlarmState,
  AlarmStateColorDisplay,
  AlarmStateDisplay,
} from '@/shared/types';
import { Link, useLocation } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import {
  AlarmLogRequest,
  AlarmLogResponse,
  deviceAssetsResponse,
  deviceAssetTypeEmun,
  getAlarmLogList,
  readAlarmLog,
} from '../../../api/alarm';
import { useHasPermission } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import { qingflowWorkDetailPath } from '../const';
import { getOuListOwned } from '../../../api/ou';
import { batchReadAlarmLog } from '../../../api/rule';
import qs from 'qs';
import RecordPagination from '../../../components/RecordPagination';
import { apiV2ServerAlarmRecordRecoveryPost } from '@maxtropy/device-customer-apis-v2';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const displayDateFormat = 'YYYY/MM/DD HH:mm:ss';

interface FilterParams {
  tenantMcid?: string;
  ouIds?: number;
  deviceCode?: string;
  deviceName?: string;
  levels?: number;
  state?: number;
  time?: [Dayjs, Dayjs];
  deviceAssetCode?: string;
}

function recordStatusOptions() {
  const status: Array<{ label: string; value: AlarmState }> = [
    {
      label: AlarmStateDisplay[AlarmState.RECOVER],
      value: AlarmState.RECOVER,
    },
    {
      label: AlarmStateDisplay[AlarmState.UNRECOVERED],
      value: AlarmState.UNRECOVERED,
    },
  ];
  return status.map(i => ({ label: i.label, value: i.value }));
}

type SearchParams = Omit<AlarmLogRequest, 'page' | 'size'>;

const AlarmRecordList: FC = () => {
  const [form] = Form.useForm();
  const [updateState, updateFn] = useUpdate();
  const location = useLocation();
  const [totalCount, setTotalCount] = useState<number>(); // 总页数

  const [isSortValue, setIsSortValue] = useState<boolean>(false); // 是否传sortvalue
  const [currentPage, setCurrentPage] = useState<number>(1); // 当前页数
  const [pageSize, setPageSize] = useState<number>(50); // 每页多少条
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const ou = useAsync(getOuListOwned, []);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const hasCreateWorkOrderPers = useHasPermission(PermissionsType.B_WORKER_ORDER_CREATE);
  const hasSubmitOrderPers = useHasPermission(PermissionsType.B_SUBMIT_WORKER_ORDER);

  const [energyAssetInfo, setEnergyAssetInfo] = useState<deviceAssetsResponse[]>([]);
  const [showEnergyAssetInfoVisible, setShowEnergyAssetInfoVisible] = useState<boolean>(false);

  //先定义两个空的数组，用于存放选中的id和数据
  const [checkedData, setCheckedData] = useState<any>([]); // 选中的数据
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);

  const columns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string, record: AlarmLogResponse) => {
        return (
          <Link to={`/device/manage/device/${record?.deviceId}/detail`} target="_blank">
            <EllipsisSpan value={v} />
          </Link>
        );
      },
    },
    {
      title: '报警等级',
      dataIndex: 'alarmLevel',
      ellipsis: { showTitle: true },
      render: (v: AlarmLevel) => (
        <EllipsisSpan
          value={
            <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
              {AlarmLevelDisplay[v]}
            </Tag>
          }
        />
      ),
    },
    {
      title: '报警状态',
      dataIndex: 'state',
      ellipsis: { showTitle: true },
      render: (v: AlarmState) => (
        <EllipsisSpan
          value={
            <Tag border="solid" color={AlarmStateColorDisplay[v]}>
              {AlarmStateDisplay[v]}
            </Tag>
          }
        />
      ),
    },
    {
      title: '规则编号',
      dataIndex: 'alarmCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '报警信息',
      dataIndex: 'alarmName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备编号',
      dataIndex: 'deviceCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '能源资产信息',
      dataIndex: 'deviceAssets',
      ellipsis: { showTitle: true },
      render: (v: string, record: AlarmLogResponse) => {
        return !record.deviceAssets || record.deviceAssets.length === 0 ? (
          '暂无'
        ) : (
          <Button type="link" onClick={() => showEnergyAssetInfo(record.deviceAssets!)}>
            点击查看
          </Button>
        );
      },
    },
    {
      title: '报警时间',
      dataIndex: 'alarmTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(displayDateFormat) : undefined} />,
    },
    {
      title: '恢复时间',
      dataIndex: 'recoveryTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(displayDateFormat) : '未恢复'} />,
    },
    {
      title: '已读时间',
      dataIndex: 'readTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(displayDateFormat) : undefined} />,
    },
  ];

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (query.ouId || query.codeOrName || query.deviceAssetCode) {
      let ouId = query?.ouId ? (isNaN(Number(query.ouId)) ? undefined : Number(query.ouId)) : undefined;
      let ouItemId = ou.find(m => m.id === ouId)?.id;
      let codeOrName = query?.codeOrName ? String(query.codeOrName) : undefined;
      let deviceAssetCode = query?.deviceAssetCode ? String(query.deviceAssetCode) : undefined;
      let state = query?.state ? (isNaN(Number(query.state)) ? undefined : Number(query.state)) : undefined;

      form.setFieldsValue({ ouIds: ouItemId, code: codeOrName, deviceAssetCode, state });
      setSearchParams({
        ...searchParams,
        ouIds: ouItemId,
        deviceCode: codeOrName,
        deviceAssetCode,
        state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, form, ou]);

  const [records, setRecords] = useState<AlarmLogResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortValues, setSortValues] = useState<any[]>();

  useEffect(() => {
    setLoading(true);
    getAlarmLogList({
      ...searchParams,
      sortValues: isSortValue ? sortValues : undefined,
      page: currentPage,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      if (res) {
        if (res.useSearchAfter) {
          setSortValues(res.sortValues);
          setIsSortValue(true);
        } else {
          setSortValues(undefined);
          setIsSortValue(false);
        }
        setRecords(res.list);
        setTotalCount(res.total);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage, setTotalCount, searchParams, updateState]);

  const onFinish = (value: FilterParams) => {
    setIsSortValue(false);
    setCurrentPage(1);
    const endtime = value.time ? dayjs(value.time[1]).endOf('day') : undefined;
    const currentTime = dayjs();
    const params: SearchParams = {
      // ouIds: value.ouIds,
      ouIds: value.ouIds,
      deviceCode: value.deviceCode,
      levels: value.levels,
      deviceName: value.deviceName,
      state: value.state,
      startTime: value.time ? dayjs(value.time[0]).startOf('day').valueOf() : undefined,
      endTime: endtime ? (endtime.isAfter(currentTime) ? currentTime : endtime).valueOf() : undefined,
      deviceAssetCode: value.deviceAssetCode,
    };
    setSearchParams(params);
  };

  const onReset = () => {
    const params: FilterParams = {
      ouIds: undefined,
      deviceCode: undefined,
      deviceName: undefined,
      levels: undefined,
      // codeOrName: undefined,
      // state: AlarmState.UNRECOVERED,
      state: undefined,
      time: undefined,
      deviceAssetCode: undefined,
    };
    setSearchParams(params);
    setCurrentPage(1);
  };

  const readLog = (id: number) => {
    readAlarmLog(id).then(res => {
      if (res.flag) {
        Modal.success({
          title: '已读！',
          okText: '确定',
          onOk() {
            updateFn();
          },
          onCancel() {
            updateFn();
          },
        });
      }
    });
  };

  const readAllLog = () => {
    Modal.confirm({
      title: '确认批量已读当前页的报警记录？',
      content: (
        <span>
          <span style={{ color: 'var(--error-color)' }}>已读后不可恢复</span>
          ，你还确认要继续吗？
        </span>
      ),
      okText: '确认',
      onOk: () => {
        batchReadAlarmLog(records.map(i => i.id)).then(() => {
          Modal.success({
            content: '已读当前页报警记录操作成功。',
            okText: '确认',
            onOk() {
              updateFn();
            },
            onCancel() {
              updateFn();
            },
          });
        });
      },
    });
  };

  const onCopy = (v: string | undefined) => {
    if (!v) return;
    navigator.clipboard.writeText(v);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 440,
      fixed: 'right' as const,
      render: (value: undefined, record: AlarmLogResponse) => {
        return (
          <Space size={16}>
            <Button
              disabled={!!record?.readTime}
              type="link"
              onClick={() => {
                readLog(record.id);
              }}
            >
              {record?.readTime ? '已读' : '确认已读'}
            </Button>
            {hasSubmitOrderPers && (
              <Button
                onClick={() =>
                  window.open(
                    `${window.ALARMWORKORDER}/workOrder/repairWorkOrder/workOrderList/workOrderAdd?${qs.stringify(
                      {
                        problemSource: 10,
                        operationUnitCode: (record.ous?.map(item => item.id) ?? []).join(','),
                        alarmId: record.id,
                        alarmTypeCode: -1,
                      },
                      { indices: false }
                    )}`,
                    '_self'
                  )
                }
                type="link"
              >
                发起工单
              </Button>
            )}
            {hasCreateWorkOrderPers && record.ruleLogWorkOrderId && (
              <Button onClick={() => window.open(qingflowWorkDetailPath, '_blank')} type="link">
                查看工单
              </Button>
            )}
            {hasCreateWorkOrderPers && !record.ruleLogWorkOrderId && (
              <Button type="link">
                <Link to={`/device/alarm/record/${record.id}/create`}>创建工单</Link>
              </Button>
            )}
            <Button type="link">
              {/* 点击设备数据查询关联属性 */}
              <Link
                to={`/data/history/device?deviceId=${record.deviceId}&alarmTime=${dayjs(record.alarmTime).valueOf()}`}
                target="_blank"
              >
                设备数据
              </Link>
            </Button>
            <Button type="link">
              <Link to={`/device/alarm/record/detail/${record.id}`}>查看</Link>
            </Button>
            <Button type="link" onClick={() => onCopy(record.alarmCode)}>
              复制规则编号
            </Button>
          </Space>
        );
      },
    },
  ];

  const showEnergyAssetInfo = (val: deviceAssetsResponse[]) => {
    setShowEnergyAssetInfoVisible(true);
    setEnergyAssetInfo(val);
  };

  const ouOptions = useMemo(() => {
    if (ou?.length !== 0) {
      return ou?.map(item => ({ label: item.name, value: item.id }));
    }
  }, [ou]);

  const alarmLevelOptions = () => {
    return Object.entries(AlarmLevelDisplay).map(i => ({
      label: i[1],
      value: i[0],
    }));
  };

  const tableSelect = (record: any, selected: any) => {
    //如果选中某一行，把选中的这行数据及id分别存到checkedData、selectedKeys中
    if (selected) {
      const arr = checkedData.map((item: any) => item.id);
      setSelectedKeys([...arr, record.id]);
      setCheckedData([...checkedData, record]);
      //取消选中，则在checkedData、selectedKeys中过滤掉这条数据
    } else {
      const newData = checkedData.filter((item: any) => item.id !== record.id);
      setCheckedData(newData);
      const arr = newData.map((item: any) => {
        return item.id;
      });
      setSelectedKeys([...arr]);
    }
  };
  // 表格全选/取消全选
  const tableSelectAll = (selected: boolean, selectedRows: any, changeRows: any) => {
    if (!selected) {
      changeRows.forEach((row: any) => {
        const newData = checkedData.filter((item: any) => item.id !== row.id);
        setCheckedData(newData);
      });
      const arr = changeRows.map((item: any) => item.id);
      const newArr = selectedKeys.filter((item: any) => !arr.some((ele: any) => ele === item));
      setSelectedKeys([...newArr]);
    }
    //全选，把
    else {
      const arr = changeRows.map((item: any) => item.id);
      setCheckedData([...checkedData, ...changeRows]);
      setSelectedKeys([...selectedKeys, ...arr]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys, //展示选中的数据
    onSelect: tableSelect, //单条数据取消/选中的回调
    onSelectAll: tableSelectAll, //全选/取消全选的回调
  };

  const adjustRestore = () => {
    Modal.confirm({
      title: `确定标记为已恢复？`,
      onOk: () => {
        apiV2ServerAlarmRecordRecoveryPost({ alarmIds: selectedKeys }).then(_ => {
          setSelectedKeys([]);
          setCheckedData([]);
          updateFn();
        });
      },
    });
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={val => {
        onFinish(val as FilterParams);
      }}
      onReset={onReset}
      collapseOpen={true}
    >
      <Form.Item name="ouIds" label="运营单元">
        <Select
          style={{ width: '100%' }}
          placeholder="请选择"
          options={ouOptions}
          showSearch
          optionFilterProp="label"
          mode="multiple"
        />
      </Form.Item>

      <Form.Item name="levels" label="报警等级">
        <Select style={{ width: '100%' }} placeholder="请选择报警等级" options={alarmLevelOptions()} mode="multiple" />
      </Form.Item>

      <Form.Item name="deviceName" label="设备名称">
        <Input placeholder="请输入设备名称查询" />
      </Form.Item>

      <Form.Item name="state" label="记录状态">
        <Select style={{ width: '100%' }} allowClear placeholder="请选择" options={recordStatusOptions()} />
      </Form.Item>

      <Form.Item name="time" label="报警日期">
        <RangePicker
          style={{ width: '100%' }}
          // defaultValue={[dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]}
          disabledDate={date => {
            return dayjs().isBefore(date);
          }}
          format={dateFormat}
        />
      </Form.Item>

      <Form.Item name="deviceAssetCode" label="能源资产编号" rules={[{ max: 30, message: '能源资产编号最多30个字符' }]}>
        <Input placeholder=" 请输入能源资产编号" />
      </Form.Item>

      <Form.Item name="deviceCode" label="设备编号">
        <Input placeholder="请输入设备编号查询" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
      <Space size={8} style={{ marginBottom: 10 }}>
        <Button type="primary" disabled={!selectedKeys.length} onClick={adjustRestore}>
          调整为已恢复
        </Button>
        <Button type="primary" disabled={(records ?? []).length === 0} onClick={readAllLog}>
          批量已读当前页
        </Button>
      </Space>
      <div>
        <Table
          sticky
          loading={loading}
          rowKey="id"
          scroll={{ x: 1900 }}
          columns={buildColumns}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={records}
        />
        {totalCount ? (
          <RecordPagination
            totalCount={totalCount}
            setPageSize={setPageSize}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsSortValue={setIsSortValue}
          />
        ) : null}
        {/* <Pagination
            defaultCurrent={1}
            total={totalCount}
            onChange={onChange}
          /> */}
        {/* <Paging pagingInfo={pagingInfo} /> */}
      </div>
      <Modal
        title="能源资产信息"
        open={showEnergyAssetInfoVisible}
        onCancel={() => setShowEnergyAssetInfoVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setShowEnergyAssetInfoVisible(false)}>
            关闭
          </Button>,
        ]}
      >
        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.PVSTATION).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>光伏能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.PVSTATION)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}

        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.ENERGYSTORAGEARRAY).length !==
          0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>储能能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.ENERGYSTORAGEARRAY)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}

        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.NETENERGY).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>微网能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.NETENERGY)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}
        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.CHARGING).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>充电站能源资产编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.CHARGING)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}
        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.TOPOLOGY).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>能源介质拓扑编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.TOPOLOGY)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}
        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.GAS_PREPARATION_STATION).length !==
          0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>气体制备站编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.GAS_PREPARATION_STATION)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}
        {energyAssetInfo.filter(item => item.deviceAssetType === deviceAssetTypeEmun.HVAC).length !== 0 && (
          <>
            <h3 style={{ fontWeight: 700 }}>暖通空调编号</h3>
            <p>
              {energyAssetInfo
                .filter(item => item.deviceAssetType === deviceAssetTypeEmun.HVAC)
                .map(i => i.deviceAssetCode)
                .join(',')}
            </p>
          </>
        )}
      </Modal>
    </Wrapper>
  );
};

export default AlarmRecordList;
